import React from 'react';
import Navbar from '../components/Navbar/Navbar.jsx';
// import EmailSignUp from '../components/EmailSignUp/EmailSignUp.jsx';
import PageHeader from '../components/PageHeader/PageHeader.jsx';

class Mae extends React.Component {

  state={
    page:'MAE'
  }
  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className='font-poppins'>
        <Navbar/>
        <PageHeader
          pageTitle='Medicaid & EPSDT'
        />
        <div className="container pt-3 pb-3">
          <p>The federal government establishes the broad framework for providing child health coverage under Medicaid – but states administer Medicaid and make many of the important decisions on who is covered, what services they cover, how they are reimbursed, who can provide the service, and who is eligible for the service. The Early Periodic Screening Diagnostic and Treatment (EPSDT) benefit requires states cover more preventive and developmental services for children, beyond what they may provide for adults. There are fifty different state Medicaid programs – and while all cover primary care services through hospitalizations – they vary dramatically, particularly around providing primary child health care and more preventive and developmental responses to children. These resource provide information on perspectives on how to use Medicaid and its EPSDT to advance child health consistent with the goals of the InCK model.</p><br/>
        </div>
        <div className="bg-light-GF">
          <div className="container pt-3 pb-3">
            <p>Health Equity and Young Children Initiative. <a href='/docs/pdfs_for_Medicaid_and_EPSDT_page/ExecutiveSummaryMEDICAIDYOUNGCHILDREN.pdf' className='text-blue' target='_blank' rel="noopener noreferrer"> A Sourcebook on Medicaid’s Role in Early Childhood. (Executive Summary – 12 pages</a> - <a href='/docs/pdfs_for_Medicaid_and_EPSDT_page/SourcebookMEDICAIDYOUNGCHILDRENALL.pdf' className='text-blue' target='_blank'> Full Sourcebook – 139 pages)</a>. This sourcebook provides data on current Medicaid programs by state, the EPSDT benefit and its requirements, and the opportunities for using Medicaid to advance “high performing medical homes” to improve child health. This includes how Medicaid can finance the primary care office visit itself, relational care coordination/case management that builds family agency in nurturing their children and links them to resources, and health-related services that improve child health trajectories (including two-generational models).</p>
          </div>
        </div>

        <div className="bg-white">
          <div className="container pt-3 pb-3">
            <p>InckMarks. <a href='/docs/pdfs_for_Medicaid_and_EPSDT_page/MedicaidChecklistforHPMH.pdf' className='text-blue' target='_blank' rel="noopener noreferrer"> Assessing Current State Medicaid Financing For Children – A Checklist</a> This resource brief offers a checklist for reviewing a state’s current Medicaid program as it relates to children and the provision of primary, preventive, and developmental health services.</p>
          </div>
        </div>

        <div className="bg-light-GF">
          <div className="container pt-3 pb-3">
            <p>Georgetown Center for Children and Families.<a href='/docs/pdfs_for_Medicaid_and_EPSDT_page/GEORGETOWNPromoting-Healthy-Development-Medicaid.pdf' className='text-blue' target='_blank' rel="noopener noreferrer"> Promoting Young Children’s Healthy Development in Medicaid and CHIP.</a> This brief provides an overview of expanding coverage and expanding services covered under Medicaid, starting with ensuring that eligible children are enrolled and receive continuous care.</p>
          </div>
        </div>

        <div className="bg-white">
          <div className="container pt-3 pb-3">
            <p> American Academy of Pediatrics and Georgetown Center for Children and Families.<a href='/docs/pdfs_for_Medicaid_and_EPSDT_page/EPSDTMedNecPPTAAPandGeorgetown.pdf' className="text-blue" target='_blank' rel="noopener noreferrer"> Medical Necessity and EPSDT: Tools for Providers and Advocates.</a> These powerpoint slides offer a national perspective on EPSDT as it is defined across the states and two state presentations on advancing child health through the EPSDT benefit.</p>
          </div>
        </div>

        <div className="bg-light-GF">
          <div className="container pt-3 pb-3">
            <p>Nemours.<a href='/docs/pdfs_for_Medicaid_and_EPSDT_page/NemoursMedicaidPaper.pdf' className="text-blue" target='_blank' rel="noopener noreferrer"> Early Childhood and Medicaid: Opportunities for Partnering</a>This report discussed how advocates and leaders in related fields can examine Medicaid and approach Medicaid agencies to expand coverage for health-related needs.</p>
          </div>
        </div>
        <div className="bg-white">
          <div className="container pt-3 pb-3">
            <p>National Center for Children in Poverty.<a href='http://nccp.org/publications/pub_1211.html' className="text-blue" target='_blank' rel="noopener noreferrer"> How States Use Medicaid to Cover Key Infant and Early Childhood Mental Health Services</a> This survey of states shows the growing use of Medicaid to finance different health-related services to support young children's social and emotional development and mental health.</p>
          </div>
        </div>

        <div className="bg-light-GF">
          <div className="container pt-3 pb-3">
            <p>Johnson Consulting Group.<a href='/docs/MedicaidHomeVisiting.pdf' target='_blank' className='text-blue' rel="noopener noreferrer"> Medicaid Financing for Home Visiting: The State of State Approaches.</a> This issue brief is based upon a survey of states and describes the ways different states have used Medicaid to finance home visiting, including targeted case management, waivers, and through managed care.</p>
          </div>
        </div>

          







      </div>
    );
  }
}

export default Mae;
