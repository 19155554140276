import React from 'react';
import Navbar from '../components/Navbar/Navbar.jsx';
import { Link } from 'react-router-dom';
import PageHeader from '../components/PageHeader/PageHeader.jsx';
import EmailSignUp from '../components/EmailSignUp/EmailSignUp.jsx';

class GettingStarted extends React.Component {
  state={
    page: 'Getting Started Page',
    buttons: [
      {name: 'State agency leaders and health policy makers', sectionID: '#Section1-GS'},
      {name: 'Child and child health advocates', sectionID: '#Section2-GS'},
      {name: 'Practitioner champions and leaders', sectionID: '#Section3-GS'},
      {name: 'Medicaid and child health experts', sectionID: '#Section4-GS'},
      {name: 'Family and community leaders', sectionID: '#Section5-GS'},
      {name: 'Overall first steps and One-Pager About InCK', sectionID: '#Section6-GS'}
    ]
  }
  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className='font-poppins'>
        <Navbar/>
        <PageHeader
          pageTitle='Getting Involved'
        />
        <div className="container mt-3 font-poppins">
          
          <p>
          The Integrated Care for Kids (InCK) model requires leadership and expertise at many levels to succeed. The Notice of Funding Opportunity (NOFO) requires a broad-based local planning council, and there are many other ways that leaders can weigh in even in the initial application development stage. This includes: state agency leaders and health policy makers, child and child health advocates, practitioner champions and leaders, Medicaid and child health experts, and family and community leaders.  
          {/* Even before the Notice of Funding Opportunity is released that will provide the application details, states and health leaders and advocates can get started in planning and collaborating. */}
          </p>
          <br/>
          <p>
          {/* The state Medicaid agency must be a lead partner for the InCK Model and there also must be a Lead Organization to coordinate local development and implementation, but neither can be successful without drawing upon the experience, knowledge, and passion of many others, including: */}
          </p>
          <br/>
          <div className="" id='guidingFrameworksLinks'>
            <div className="row">
              {this.state.buttons.map(button=>(
                <div className="col-md-6 col-lg-4 text-center"><a className=' btn m-1 p-1 h-50-px bg-purple font-weight-bolder pt-2 text-white' href={button.sectionID}>{button.name}</a></div>
              ))}
            </div>
          </div>
          <div className="mt-3 mb-3 d-flex justify-content-between">
            <div className='divImage' style={{'backgroundImage': 'url(/images/dentistchild.jpeg)'}}/>
            <div className='divImage' style={{'backgroundImage': 'url(/images/doctorhighfive.jpg)'}}/>
            <div className='divImage' style={{'backgroundImage': 'url(/images/dentist.jpg)'}}/>
          </div>
        </div>
        <div className="bg-light-GF" id='Section1-GS'>
          <div className="container pb-3">
            <div className="sticky-top-light mb-3">
              <h5 className='pb-3 text-maroon border-bottom'>State agency leaders and health policy makers</h5>
            </div>
            <p>Medicaid agencies and state policy makers (legislators and executive branch leaders) can reach out to advocates, practitioner champions and leaders, and family and community leaders at the outset to identify the most promising activities for improving child health in the state. While some of the focus in the InCK model will be upon developing risk stratification tools, actuarial estimates, and payment incentives and models that require technical expertise – these will only achieve their goals if they are built upon an understanding of what it requires to transform practice at the ground level.</p>
          </div>
        </div>

        <div className="bg-white" id='Section2-GS'>
          <div className="container pb-3">
            <div className="sticky-top-white mb-3">
              <h5 className='pb-3 text-maroon border-bottom'>Child and child health advocates</h5>
            </div>
            <p>Child and child health advocates can keep the focus upon improving child health results, identifying current health needs and disparities for children, engaging other partners and constituencies that contribute to success, and building public support and will. Child and child health advocates can be proactive in offering their expertise and passion to the state at the outset of this work.</p><br/>
            <p>Resource: 
              <a href="/GS/InCK-Child-Advocates-November-8.pdf" target='_blank' className='text-blue'> Webinar presentation to Child Advocates on the InCK Model.</a>
            </p>
          </div>
        </div>

        <div className="bg-light-GF" id='Section3-GS'>
          <div className="container pb-3">
            <div className="sticky-top-light mb-3">
              <h5 className='pb-3 text-maroon border-bottom'>Practitioner champions and leaders</h5>
            </div>
            <p>Practitioner champions and leaders can provide examples of high performing practices that improve child health, along with what is needed to spread such effective practice in the field. They can serve as leaders in implementation efforts to produce success and ensure that the focus is upon achieving gains at the practice level through building upon best practice</p><br/>
            <p>Resource: 
              <a href="/GS/HEYC-Framework-Paper-PDF.pdf" target='_blank' className='text-blue'> Framework paper from Health Equity and Young Children Learning Collaborative</a>
            </p>
          </div>
        </div>

        <div className="bg-white" id='Section4-GS'>
          <div className="container pb-3">
            <div className="sticky-top-white mb-3">
              <h5 className='pb-3 text-maroon border-bottom'>Medicaid and child health experts</h5>
            </div>
            <p>Medicaid and child health experts can show how Medicaid and its EPSDT benefit can and should advance health practice to be more preventive, developmental, and effective in responding to children’s needs. They can support other participants in understanding Medicaid and its EPSDT benefit and the appropriate role for Medicaid in financing health services.</p><br/>
            <p>Click here for <Link to ='/MedicaidAndEPSDT' className='text-blue'> Medicaid and EPSDT Resources</Link></p>
          </div>
        </div>

        <div className="bg-light-GF" id='Section5-GS'>
          <div className="container pb-3">
            <div className="sticky-top-light mb-3">
              <h5 className='pb-3 text-maroon border-bottom'>Family and community leaders</h5>
            </div>
            <p>Family and community leaders can show both the value of and need for family-centered child health practices that engage families as partners. They can help ensure that approaches recognize the family’s core role and the need for flexible responses that build upon that role and that access and build needed community supports.</p>
          </div>
        </div>

        <div className="bg-white" id='Section6-GS'>
          <div className="container pb-3">
            <div className="sticky-top-white mb-3">
              <h5 className='pb-3 text-maroon border-bottom'>Overall first steps and One-Pager About InCK</h5>
            </div>
            <p>Potential participants from all the above groups have an opportunity to make themselves known – to their Medicaid agencies and to others most likely to be involved – on their interest in participating and the expertise they bring. This includes review of the InCK opportunity and its relevance to the state and its children:</p><br/>
            <ul>
              <li>Setting out initial goals for the most important activities to improve child health (with or without selection as an InCK model site)</li><br/>
              <li>Identifying the current capacities upon which to build, including different exemplary service models already in place</li><br/>
              <li>Exploring the particular roles that different community partners and state-level advocates can play in the review and design</li><br/>
              <li>Bringing the family voice and expertise into the planning process and approach</li><br/>
              <li>Identifying the most promising geographic service area (or areas) for the InCK model itself</li><br/>
            </ul>
            <br/>
            <p><span className='text-red'>* </span>A one-page description of the InCK Model is listed below and can be downloaded <a href="/docs/multiplepagepdfs/InCKMarksOnePagerSummary.pdf" target='_blank' className='text-blue'>Here.</a></p>
            <div className="border shadow-box bg-light-GF mt-3 rounded">
              <h5 className="text-center pt-3 pb-2 border-bottom">InCK Marks: CMMI’S INTEGRATED CARE FOR KIDS (InCK) MODEL</h5>
              <div className="p-2">
                <p>The Center for Medicare and Medicaid Services Innovation Center (CMMI) was established as part of the Affordable Care Act to test models that “improve care, lower costs, and better align payment systems to support patient-centered practices.” The CMMI Integrated Care for Kids (InCK) model is CMMI’s first completely child-focused model developed to advance health payment and delivery system reform – with a particular focus upon the unique needs and opportunities for preventive and developmental services for children. While the detailed federal application announcement (the Notice of Funding Opportunity/NOFO) has not yet been released, CMMI has provided a general framework that provides sufficient information for states to begin planning.</p>
                <br/>
                <h6 className="font-weight-bold text-maroon">STRUCTURE </h6>
                <p>
                  Through a competitive grant process, the CMMI will award grants to eight states of up to $16 million each over a seven-year period for the InCK Model. The first two years will be for pre-implementation planning and the next five for implementation. InCK funding may be used only to support model planning and implementation activities (existing funding sources must be used to deliver services). The state Medicaid agency must participate and provide local implementation support, with a Lead Organization (which could be the Medicaid agency) convening community partners to integrate coordination and management of the InCK Model’s core child services.
                </p><br/>
                <h6 className="font-weight-bold text-maroon">POPULATION </h6>
                <p>
                  InCK Model participants will serve all children covered by Medicaid and CHIP, if applicable, from the prenatal period up to 21 years of age residing within an awardee-specified (and CMS-approved), sub-state geographic service area.
                </p><br/>
                <h6 className="font-weight-bold text-maroon">GOALS </h6>
                <p>
                  The goals of the InCK Model are to improve child health (including prevention), reduce substance use disorders and opioid use, reduce avoidable inpatient stays and out of home placements, and create sustainable Alternative Payment Models (APMs) through a framework of child-centered care integration across behavioral, physical, and other child providers.
                </p><br/>
                <h6 className="font-weight-bold text-maroon">SPECIFIC SERVICES </h6>
                <p>The InCK service integration structure is based on population-wide risk-stratification according to level of need. Lead organizations will coordinate the integration of core child service types: (1) clinical care (behavioral and physical), (2) early care and education, (3) Title V agencies, (4) schools, (5) housing, (6) child welfare service (7) food, and (8) mobile crisis response services.</p><br/>
                <h6 className="font-weight-bold text-maroon">GETTING STARTED </h6>
                <p>While CMMI anticipates a significant planning period, states and child health experts, child advocates, and child health practitioners and champions do not need to wait for the NOFO to appear. In particular, individuals and organizations can contact their Medicaid agencies and others involved most likely to be involved – showing their interest in participating in the review of the InCK opportunity and the development of a state application. Doing so can have a positive impact in:</p><br/>
                <ul>
                  <li>Identifying the most promising geographic service area for the InCK model itself</li><br/>
                  <li>Identifying the current capacities upon which to build, including different exemplary service models already in place</li><br/>
                  <li>Exploring the particular roles that different community partners and state-level advocates can play in the review and development of the proposal</li><br/>
                  <li>Setting out initial ideas for the most promising activities for the state to improve child health, with or without selection as an InCK model site</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
        <EmailSignUp/>
        </div>
      </div>
    );
  }
}

export default GettingStarted;
