import React from 'react';
import Navbar from '../components/Navbar/Navbar.jsx';
import PageHeader from '../components/PageHeader/PageHeader.jsx';

class InckBasics extends React.Component {
  state={
    page: 'InCK Basics Page'
  }
  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <Navbar/>
        <PageHeader
          pageTitle='InCK Basics'
        />
        <div className="container mt-3 font-poppins">
          <p>
            The Center for Medicare and Medicaid Services Innovation Center (CMMI) was established in law as part of the Affordable Care Act to test models that “improve care, lower costs, and better align payment systems to support patient-centered practices.”
          </p>
          <br/>
          <p>
            Through a Request for Information (RFI) in 2017, CMMI sought advice from the field on developing alternative payment models for children covered under Medicaid and CHIP. To that point in time, most of the emphasis of CMMI funding for innovations had been on the adult or the high cost, chronic-care populations served under Medicare and Medicaid.
          </p>
          <br/>
          <p>
            On August 21, 2018, the CMMI announced the Integrated Care for Kids (InCK) Model as a competitive grant program specifically to cover the child population (prenatal to 21) – with a fact sheet describing the model and a subsequent webinar describing the approach.
          </p>
          <br/>
          <p>At that time, CMMI provided a general framework to begin planning:</p>
          <ul>
            <li>
              Through a competitive grant process, eight states will be awarded up to $16 million each for the plan and design of an Integrated Care for Kids pediatric model;
            </li>
            <br/>
            <li>
              The state Medicaid agency must be a partner with a Lead Organization (responsible for convening community partners) to develop the model in a specific geographic area or community, with the funding directed to design and implementation (not services); and
            </li>
            <br/>
            <li>
              The model must cover the entire child Medicaid (and CHIP) population prenatally through 21 and employ risk stratification to develop different effective models of care for different populations.
            </li>
            <br/>
          </ul>
          <p className='border-top pt-3'>On February 8th, 2019, CMMI released its Notice of Funding Opportunity (NOFO), summarizing the InCK Model, describing the application process, and enumerating the elements that must be included in an application (application and submission information). Proposals are due on June 10, 2019.</p>
          <br/>
          <p><a href='/docs/InCKNoticeofFederalOpportunity.pdf' target='_blank' rel="noopener noreferrer" className='font-poppins text-blue'>The Integrated Care for Kids Model Notice of Funding Opportunity</a></p>
          <br/>
          <div className="p-3 bg-lightblue shadow-box">
            <p>
              Based upon this information, InCKMarks has produced an Initial <a href="/docs/multiplepagepdfs/InCKMarksOnePagerSummary.pdf" className='text-blue'> One Page Summary</a> along with some ideas of where states may benefit from help.
            </p>
            <br/>
            <p>
              CMMI resources and source materials include:
            </p>
            <br/>
            <p>
              <a href='/docs/CMMI-Fact-Sheet.pdf' download="CMMI-Fact-Sheet.pdf" className='text-blue'>Fact Sheet</a> providing an overall description of the InCK Model
            </p>
            <br/>
            <p>
              <a href='/docs/CMMI-InCK-Model-Sepember-Webinar.pdf' download='CMMI-InCK-Model-Sepember-Webinar.pdf' className='text-blue'>Webinar powerpoint</a> from a September 12th webinar from CMS.
            </p><br/>
            <p>
            CMMI also has developed an sign-up list to obtain updates on the model: 
            </p><br/>
            <p className='text-center'><a href="https://public.govdelivery.com/accounts/USCMS/subscriber/new?topic_id=USCMS_610" target='_blank' rel="noopener noreferrer" className='text-lightblue rounded btn bg-blue p-1'> Sign Up Here</a></p>
            
          </div>
          <br/>
         
        </div>
      </div>
    );
  }
}

export default InckBasics;
