import React, { Component } from 'react';
import {Link} from 'react-router-dom';
// import logo from './logo.svg';

class Navbar extends Component {
  state={
    navShow: 'd-none',
    linkShow: 'hideLinks',
    backGround: ''
  }

  displayNav=()=>{
    if (this.state.navShow === 'd-none'){
      this.setState({navShow: 'sideNavOpen', linkShow: 'showLinks', backGround: 'hiddenBG'})
    }
    else{
      this.setState({navShow: 'd-none', linkShow: 'hideLinks', backGround: 'd-none'})
    }
  }

  render() {
    return (
      <div style={{'backgroundColor': '#0099FF'}}>
        <div className="bg-light pt-2 pb-2">
          <div className="container">
            <div className="d-flex justify-content-between">
              <Link to='/home'><h1 className='font-fred text-lg' style={{'color': '#0099FF'}}>In<span className="text-blue">CK</span> Marks</h1></Link>
              <h1 
                id='navButton' 
                className='text-lg fas fa-bars text-blue'
                onClick = {this.displayNav}
              > </h1>
            </div>
          <p id='navSummary' style={{'lineHeight': '16px'}} className='font-poppins'>Resources to help leaders advance child health care transformation</p>
          </div>
        </div>
        <div className="container" id='navLinks'>
          <div className=" text-sm font-poppins text-green pt-1 pb-1 d-flex justify-content-between">
            <Link to='/OurPartners' className='font-weight-bold text-light'>Our Partners</Link>
            <Link to='/GuidingFramework' className='font-weight-bold text-light'>Guiding Framework</Link>
            <Link to='/KeyResources' className='font-weight-bold text-light'>Key Resources</Link>
            <Link to='/FederalInckModel' className='font-weight-bold text-light'>Federal InCK Model</Link>
            {/* <Link to='/InckBasics' className='font-weight-bold text-light'>InCK Basics</Link>
            <Link to='/GettingInvolved' className='font-weight-bold text-light'>Getting Involved</Link> */}
            
            
            <Link to='/Contact' className='font-weight-bold text-light'>Contact</Link>
            <Link to='/Webinars' className='font-weight-bold text-light'>Webinars</Link>
          </div>
        </div>
        <div className={this.state.backGround} onClick={this.displayNav}/>
        <div id={this.state.navShow}>
          <div className={`${this.state.linkShow} text-center`}>
            <h5 className='d-inline font-poppins font-weight-bold text-light mt-3 mb-3 pb-2 border-bottom border-white'>Site Navigation</h5>
            <Link to='/OurPartners' >Our Partners</Link>
            <Link to='/GuidingFramework' >Guiding Framework</Link>
            <Link to='/KeyResources' >Key Resources</Link>
            
            <Link to='/Webinars' >Webinars</Link>
            <Link to='/FederalInckModel' >Federal InCK Model</Link>
            
            <Link to='/Contact' >Contact</Link>
            <div className="custom-bottom text-center">
              <div className="bg-light circle d-inline-block p-1">
                <img src="/images/inckMarks.png" alt="" className='img-height-40'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;