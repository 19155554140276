import React from 'react';

const PageHeader = (props)=> {
    return (
      <div className="mainImage text-center">
      <div className='w-100 h-300-px bg-muted opaque-5' style={{'position': 'absolute'}}/>
        <div className='bg-light opaque-8 d-inline-block' style={{'position': 'relative', 'top': '130px'}}>
          <p 
            className='h1 font-marker font-weight-bold pt-2 pb-2 pl-1 pr-1 text-center' 
            id='pageTitle'
          >
            {props.pageTitle}
          </p>
        </div>
      </div>
    );
}

export default PageHeader;




