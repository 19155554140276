import React, { Component } from 'react';
// import {Link} from 'react-router-dom';

class AddPartner extends Component {
  constructor(props){
    super(props);

    this.state={
      newPartner: false,
      name: '',
      webUrl: '',
      image: '',
      summaryShort: '',
      summaryLong: '',
      constituency: '',
      howHelps: '',
      articles: [],
      keyContacts: [1,2],
      categories: []
    }
    this.componentDidMount=()=>{
      console.log(props)
    }
    this.componentWillReceiveProps=()=>{
      console.log(props)
    }
  }
  render() {
    return (
      <div id="addpartnermodal">
        <div className='shadow-box bg-lightblue p-2'>
          <h3>Add A Partner</h3>
          <input type="text" className="adminInputBox-Header" placeholder='Name of the Organization'/>
          <div className="row mb-3">
            <div className="col-md-4">
              <input type="text" className="adminInputBox" placeholder='Website'/>
              <input type="text" className="adminInputBox" placeholder='Organization Logo'/>
              <textarea type="text" className="adminInputBox-ta" style={{'minHeight': '264px'}} placeholder='A Short Summary'/>
            </div>
            <div className="col-md-8">
              <textarea type="text" className="adminInputBox-ta" placeholder='A Longer Summary'/>
              <textarea type="text" className="adminInputBox-ta" placeholder='Constituency/Members'/>
              <textarea type="text" className="adminInputBox-ta" placeholder='How They Help'/>            
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-4">
              <p>Add A New Contact</p>
              <input type="text" className="adminInputBox" placeholder='New Key Contact'/>
              <input type="text" className="adminInputBox" placeholder='Their Title'/>
              <textarea type="text" className="adminInputBox" placeholder='Areas of Expertise'/>
            </div>  
            <div className="col-md-8">
              <p>Edit Current Contacts</p>
              {this.state.keyContacts.map(contact=>(
                <div className='border bg-muted p-1 mt-2'>
                  <input type="text" className="adminInputBox" style={{'marginTop': '0px'}} placeholder='New Key Contact'/>
                  <input type="text" className="adminInputBox" placeholder='Their Title'/>
                  <textarea type="text" className="adminInputBox" placeholder='Areas of Expertise'/>
                </div>
              ))}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p>Add A New Article</p>
              <input type="text" className="adminInputBox" placeholder='Article Name'/>
              <input type="text" className="adminInputBox" placeholder='Article Link'/>
              <input type="text" className="adminInputBox" placeholder='Type of publication'/>
            </div>
            <div className="col-md-8">
              <p>Edit A Current Article</p>
              {this.state.articles.map(contact=>(
                <div className='border bg-muted p-1 mt-2'>
                  <input type="text" className="adminInputBox" style={{'marginTop': '0px'}} placeholder='Article Name'/>
                  <input type="text" className="adminInputBox" placeholder='Article Link'/>
                  <input type="text" className="adminInputBox" placeholder='Type of publication'/>
                </div>
              ))}
            </div>
          </div>
          <div className="text-center">
            <button id='submit-Admin' className='font-poppins'>Save Changes</button>
          </div>
        </div>
      </div>
    );
  }
}

export default AddPartner;