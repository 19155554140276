import React, { Component } from 'react';
import Navbar from '../../components/Navbar/Navbar.jsx';
import PageHeader from '../../components/PageHeader/PageHeader.jsx';

class ValueBasedCare extends Component {
  state = { 
    resources:[
      {
        title: 'Value-Based Care and High Performing Medical Homes for Young Children', 
        link: '/docs/keyissues/OptimizingPaymentVALUEBASEDCARE.pdf',
        author: '(Child and Family Policy Center).', 
        desc: 'This chapter of the sourcebook on Medicaid and Young Children describes the issues and opportunities in developing value-based care and payment systems for young children who do not have high medical costs.'
      },
      {
        title: 'Alternative Payment Models for Pediatrics: Operationalizing Value-Based Care Over the Life Course',
        link: '/docs/keyissues/ALTPAYMENTMODELSjointMHAHMGCFPC.pdf', 
        author: '(Child and Family Policy Center: Mental Health America, Help Me Grow National Center)', 
        desc: 'This commentary describes key themes from organizations responding to the CMMI’s RFI on child health in relation to establishing value-based payment options.'
      },
      {
        title: 'Achieving Payment Reform for Children Through Medicaid and Stakeholder Collaboration',
        link: '/docs/keyissues/achieving_payment_reform_for_children_uhf2.pdf', 
        author: '(United Hospital Fund)', 
        desc: 'This brief explores key issues in developing value-based payment systems for children, which require different approaches and stakeholders than dealing with payment systems for high cost adults.'
      },
      {
        title: 'Risk Stratification for Children in Medicaid: Achieving the Potential of Prevention.',
        link: '/docs/RiskStratificationandYoungChildrenFinalMay3pdf.pdf',
        author: '(InCK Marks Resource Network and Child and Adolescent Health Measurement Initiative)',
        desc: 'Risk stratification for the child population plays a key role in the InCK Model for designing both service strategies and alternative payment models. This resource brief describes key opportunities in developing a risk stratification approach to advance more preventive and developmental health services.'
      },
      {
        title: 'Achieving Payment Reform for Children Through Medicaid and Stakeholder Collaboration',
        link: '/docs/achieving_payment_reform_for_children_uhf.pdf',
        author: 'United Hospital Fund',
        desc: 'This brief explores key issues in developing value-based payment systems for children, which require different approaches and stakeholders than dealing with payment systems for high cost adults.'
      },
      {
        title: 'Payment for Progress: Investing to Catalyze Child & Family Well-Being.',
        link: '/docs/payment_for_progress_fullreport_nov2018.pdf',
        author: 'Child and Adolescent Health Measurement Initiative (CAHMI) and Academy Health',
        desc: 'This report outlines strategic opportunities emerging from the “Payment transformation to address social and emotional determinants of health for children” project.'
      },
      {
        title: 'Community-Centered Health Homes: Bridging Healthcare Services and Community Prevention. 2nd Edition.',
        link: '/docs/CCHH_2019.pdf',
        author: 'The Prevention Institute ',
        desc: 'This second edition provides an updated description of the community-centered health home model based on the experiences of healthcare organizations piloting it, offers practical strategies for implementation, and includes a tool healthcare organizations can use as they embark on efforts to improve community health.'
      },
    ]
  }

  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }
  render() { 
    return ( 
      <div className='font-poppins'>
        <Navbar/>
        <PageHeader
          pageTitle='Value-Based Care, Risk Stratification, and Preventive Health'
        />

        <div className="container mt-3">
          <p>Value-based payment (VBP) is a form of reimbursement that ties payments for care delivery to the quality of care provided and rewards providers for both efficiency and effectiveness (pay for value, not volume). It has emerged as an alternative to fee-for-service reimbursement which pays providers for services delivered. VBP systems often are developed to provide incentives to reduce health expenditures by providing higher value services to populations with high health risks which reduce subsequent costs for new, high cost episodes of medical care. The InCK model emphasizes the use of VBP for risk-stratified populations to improve health quality and reduce overall costs. For other child populations, however, more preventive services are needed to improve quality and healthy development – but these do not necessarily produce medical cost savings. The InCK model stresses service reforms for these populations.</p>
          <h5 className="text-maroon mt-3 mb-2 pb-2 border-bottom">Resources</h5>
          <br/>
          {this.state.resources.map(resource=>(
            <div key='title' className='bg-light-GF rounded p-2 mb-2'>
              <a href={resource.link} target='_blank' rel="noopener noreferrer">
                <h6 className='text-blue'>{resource.title}</h6>
              </a>
              <p className="mt-1 text-muted text-sm font-weight-bold">{resource.author}</p>
              <p className='mt-1 '>{resource.desc}</p> 
            </div>
          ))}
        </div>
      </div>
     );
  }
}
 
export default ValueBasedCare;