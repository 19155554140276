import React, { Component } from 'react';
import { Redirect, BrowserRouter, Route, Switch} from 'react-router-dom';
import Footer from './components/Footer/Footer.jsx';
import LoginPage from './pages/Login.jsx';
import Admin from './pages/Admin.jsx';
import Home from './pages/Home.jsx';
import InckBasics from './pages/InCKBasics.jsx';
import Contact from './pages/Contact.jsx';
import GettingStarted from './pages/GettingStarted.jsx';
import GuidingFramework from './pages/GuidingFramework.jsx';
import KeyIssues from './pages/KeyIssues.jsx';
import OurPartners from './pages/OurPartners.jsx';
import Partner from './pages/Partner.jsx';
import Mae from './pages/Mae.jsx';
import Test from './pages/Test.jsx';
import AboutCharles from './pages/AboutCharles.jsx';
import EECPC from './pages/CoreIssuesSubPages/EECPC.jsx';
import ValueBasedCare from './pages/CoreIssuesSubPages/ValueBasedCare.jsx';
import OpioidsAndChildHealth from './pages/CoreIssuesSubPages/OpioidsAndChildHealth.jsx';
import Webinars from './pages/Webinars';
import KeyResources from './pages/KeyResources.jsx';

let cookieObj={};

const Auth = {
  isAuthenticated: false,
  authenticate(cb) {
    let cookieVars=document.cookie;

    // console.log(cookieVars);
    if(cookieVars!==undefined){
      //console.log("in cookievars if")
    cookieVars=cookieVars.replace(/=/g, " ")
    let cookieArray= cookieVars.split(" ")
    //console.log(cookieArray)
    let url;
    let hash;
    let port;
    let username;

      if(cookieArray.length>6){
        for(let i=0; i<cookieArray.length; i++){
        //console.log("in cookiearray if")
          if(cookieArray[i]==="username"){
            if(cookieArray[i+1][cookieArray[i+1].length]===";"){
              username=cookieArray[i+1].substring(0, cookieArray[i+1].length-1)
            }else{
              username=cookieArray[i+1].substring(0, cookieArray[i+1].length)
            }
          }
          if(cookieArray[i]==="port"){
            port=parseInt(cookieArray[i+1])
          }
          if(cookieArray[i]==="hash"){
            hash=cookieArray[i+1].substring(0, cookieArray[i+1].length-1)
          }
          if(cookieArray[i]==="url"){
            url=cookieArray[i+1].substring(0, cookieArray[i+1].length)
          }
        }
        cookieObj.username=username;
        cookieObj.port=port;
        cookieObj.hash=hash;
        cookieObj.url=url;
        // console.log("this is cookie object")
        // console.log(cookieObj)
        
        if(cookieObj.username!==undefined && cookieObj.username.length>5)
        {
          //console.log(cookieObj.username)
        //this.setState({ redirectToReferrer: true });
        
        this.isAuthenticated = true;
        return true;
        }
      }
    }else{
      this.isAuthenticated = false;
      return false
      //console.log("not logged in")
    } 
  },
  signout(cb) {
    this.isAuthenticated = false;
    //this.setState({ redirectToReferrer: false });
  }
};
const PrivateRoute = ({ component: Component, ...rest }) => (
  Auth.authenticate(),
  <Route
    {...rest}
    render={(props) => 
      Auth.isAuthenticated ===true 
      ? ( <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <Switch>
            <Route path='/' component = {Home} exact/>
            <Route path='/home' component = {Home} exact/>
            <Route path='/Contact' component = {Contact} exact/>
            <Route path='/GettingInvolved' component = {GettingStarted} exact/>
            <Route path='/GuidingFramework' component = {GuidingFramework} exact/>
            <Route path='/FederalInCKModel' component = {KeyIssues} exact/>
            <Route path='/KeyResources' component = {KeyResources} exact/>
            <Route path='/KeyIssues/EECPC' component={EECPC} exact/>
            <Route path='/KeyIssues/OpioidsAndChildHealth' component={OpioidsAndChildHealth} exact/>
            <Route path='/KeyIssues/ValueBasedCare' component={ValueBasedCare} exact/>
            <Route path='/InckBasics' component = {InckBasics} exact/>
            <Route path='/OurPartners' component = {OurPartners} exact/>
            <Route path='/OurPartners/:nameTrunc' component = {Partner} exact/>
            <Route path='/login' component = {LoginPage}/>
            <Route path='/MedicaidAndEPSDT' component = {Mae}/>
            <Route path='/test' component = {Test}/>
            <Route path='/aboutCharlesBruner' component = {AboutCharles}/>
            <Route path = '/Webinars' component = {Webinars}/>
            <PrivateRoute path='/admin' component={Admin} exact/>
            <Route path='*' component = {Home}/>
          </Switch>
        </BrowserRouter>
        <Footer/> 
      </div>
    );
  }
}

export default App;
