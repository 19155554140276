import React from 'react';
import Navbar from '../components/Navbar/Navbar.jsx';
import EmailSignUp from '../components/EmailSignUp/EmailSignUp.jsx';
import PageHeader from '../components/PageHeader/PageHeader.jsx';

class Webinars extends React.Component {

  state={
    page:'Webinars Page'
  }
  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <Navbar/>
        <PageHeader
          pageTitle='Webinars'
        />
        <div className="container mt-3 font-poppins">
          
          <p>InCK Marks is conducting a series of webinars to provide information on different topics related to the InCK Marks model and its application guidelines, drawing upon the national advisory team and resource partners. The webinars are scheduled for every other Tuesday, at 2:00 p.m. eastern time and the presentations and slides will be posted when they have been conducted. Register below for webinar registration notices. </p>
          <br/>
          <h3>INCK MARKS WEBINAR SERIES DESCRIPTIONS</h3>
          <br/>
          <p>March 5th Webinar: <span className='text-maroon h6 font-weight-bold'>Overview: The InCK Model and the InCK Marks Network</span>. Presenters: Charles Bruner and Maxine Hayes. Describes the basics of the InCK Model and the framework InCK Marks is employing to provide resources for those interested in using the InCK Model to improve child health.</p>
          <br/>
          <div className="row">
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border mr-3"><a href='https://www.dropbox.com/s/6l2agv9bsrqz5ty/InCK%20webinar%2003-05-2019.mp4?dl=1' target='_blank' rel="noopener noreferrer">Download the March 5th Webinar</a></div>
            <div className="col-sm-2"></div>
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border"><a href='/docs/INTRODUCTIONWEBINARINCKMarch5.pdf' target='_blank' rel="noopener noreferrer">Download the March 5th Webinar Slides Slides</a></div>
           
          </div>
          <br/>

          <p>March 19th Webinar: <span className='text-maroon h6 font-weight-bold'> The InCK Model, Medicaid, and the EPSDT Benefit</span>. Presenters: Kay Johnson and Elisabeth Burak. Describes the opportunity to realize the EPSDT Benefit in relation to developing integrated service responses under the InCK Model.</p>
          <br/>
          <div className="row">
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border mr-3"><a href='https://www.dropbox.com/s/6g2wzqkl78wrw79/InCK%20webinar%2003-19-2019%20recording.mp4?dl=0' target='_blank' rel="noopener noreferrer">Download the March 19th Webinar</a></div>
            <div className="col-sm-2"> </div>
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border"><a href='/docs/INCKwebinar_EPSDT_March19_final.pdf' target='_blank' rel="noopener noreferrer">Download the March 19th Slides</a></div>
          </div>
          <br/>
          <p>April 2nd Webinar: <span className='text-maroon h6 font-weight-bold'>The InCK Model, Family Engagement, and Family-Centered Care</span>. Presenters: Nora Wells, Charles Bruner, and Shadi Houshyar. Describes how family engagement and family-centered care is embedded in the InCK Model and family advocates and leaders can inform those designing InCK Models to engage families in both design and delivery.</p>
          <br/>
          <div className="row">
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border mr-3"><a href='https://www.dropbox.com/s/u8tux9femjuc25f/InCK%20webinar%2004-02-2019%20recording.mp4?dl=0' target='_blank' rel="noopener noreferrer">Download the April 2nd Webinar</a></div>
            <div className="col-sm-2"> </div>
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border"><a href='/docs/InCKandFamilyEngagementWebinarrevised4-2-19.pdf' target='_blank' rel="noopener noreferrer">Download the April 2nd Slides</a></div>
          </div>
          <br/>
          <p>April 9th Webinar: <span className='text-maroon h6 font-weight-bold'> The InCK Model, Prevention, and Building a Culture of Health.</span> Presenters: Martha Davis and Charles Bruner. Describes the Robert Wood Johnson Foundation’s Building a Culture of Health and how the InCK model can advance that work. Provides guidance for child health champions in helping InCK applicants incorporate a strong, evidenced-based prevention approach through the root cause analysis and risk stratification elements within InCK. </p>
          <br/>
          <div className="row">
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border mr-3"><a href='https://www.dropbox.com/s/uyy1dish1vywz7a/InCK%20webinar%2004-09-2019%20recording.mp4?dl=0' target='_blank' rel="noopener noreferrer">Download the April 9th Webinar</a></div>
            <div className="col-sm-2"> </div>
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border"><a href='/docs/INCKandPREVENTION4.9.19.pdf' target='_blank' rel="noopener noreferrer">Download the April 9th Slides</a></div>
          </div>
          <br/>
          {/*  */}
          <p>April 30th Webinar: <span className='text-maroon h6 font-weight-bold'>The InCK Model, Two-Generation Strategies, and the First Thousand Days.</span> Presenters: Charles Bruner and Marjorie Sims. Describes the special opportunities within the InCK model for responding to young children in the context of their families and from a two-generation approach. </p>
          <br/>
          <div className="row">
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border mr-3"><a href='https://www.dropbox.com/s/bcusjfiz9nw4kdx/InCK%20webinar%2004-30-2019%20recording.mp4?dl=0' target='_blank' rel="noopener noreferrer">Download the April 30th Webinar</a></div>
            <div className="col-sm-2"> </div>
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border"><a href='/docs/INCKandFirst1000DaysFinal4.30.19.pdf' target='_blank' rel="noopener noreferrer">Download the April 30th Slides</a></div>
          </div>
          <br/>
          <p>May 7th Webinar: <span className='text-maroon h6 font-weight-bold'>May 7th Webinar: The InCK Model and Health Equity.</span> Presenters: Kay Johnson, Jeff Hild, and Maxine Hayes. Describes the importance of focusing upon achieving health equity to meet the objectives of the InCK Model.</p>
          <br/>
          <div className="row">
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border mr-3"><a href='https://www.dropbox.com/s/yifx0n1dcsoiuwf/Inck%20webinar%2005-07-2019%20recording.mp4?dl=0' target='_blank' rel="noopener noreferrer">Download the May 7th Webinar</a></div>
            <div className="col-sm-2"> </div>
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border"><a href='/docs/InCKandEquity_CB_KJ_JF_MH_final050719_footer.pdf' target='_blank' rel="noopener noreferrer">Download the May 7th Slides</a></div>
          </div>

        <br/>
        <br/>

          <br/>
          <p>March 26th, 2020 Webinar: <span className='text-maroon h6 font-weight-bold'>Child Health Care Transformation:
Making the Case.</span> </p>
          <br/>
          <div className="row">
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border mr-3"><a href='https://www.dropbox.com/s/kgwu0muie8x11ns/WebinarOneInCKOverviewM26%203-26-2020.mp4?dl=0' target='_blank' rel="noopener noreferrer">Download the March 26th, 2020 Webinar</a></div>
            <div className="col-sm-2"> </div>
            <div className="col-sm-5 text-center pt-3 pb-3 bg-light gray border"><a href='/webinars/WebinarOneInCKOverviewM26.pdf' target='_blank' rel="noopener noreferrer">Download the March 26th, 2020 Slides</a></div>
          </div>
        {/* InCKandEquity_CB_KJ_JF_MH_final050719_footer */}







          <EmailSignUp/>
        </div>
      </div>
    );
  }
}

export default Webinars;
