import React from 'react';
import Navbar from '../components/Navbar/Navbar.jsx';
import EmailSignUp from '../components/EmailSignUp/EmailSignUp.jsx';
import PageHeader from '../components/PageHeader/PageHeader.jsx';

class Contact extends React.Component {

  state={
    page:'Contact Page'
  }
  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <Navbar/>
        <PageHeader
          pageTitle='Contact'
        />
        <div className="container mt-3 font-poppins">
        
          <p>We are going to be updating this website and providing webinars and other resources for the field. We encourage you to sign up to receive updates and notices related to our work</p>
          <br/>
          <p>We also want to hear from you regarding your thoughts or questions about the InCK model and improving child health – or with your own resources to support work in the field. Please contact us with your thoughts, questions, and resources and insights.</p>
          <br/>
          <p>Finally, the CMS Innovation Center has developed a listserv.</p>
          <br/>
          <br/>
          <div className="text-center">
            <a href="mailto:info@inckmarks.org" className='text-white h3 p-2 rounded bg-info'>
              <i className="fas fa-envelope-square"/>
              <span> info@inckmarks.org</span>
            </a>
          </div>
          <br/>
          <EmailSignUp/>
        </div>
      </div>
    );
  }
}

export default Contact;
