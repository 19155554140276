import React from 'react';
// import {Link} from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar.jsx';
import PageHeader from '../components/PageHeader/PageHeader.jsx';

export default class KeyResources extends React.Component {
  state={
    page: 'Key Resources',
    startingResources: [
      {name: 'Medicaid and EPDST', sentence: 'Medicaid and its EPSDT benefit provide multiple opportunities for improving child health.', link: '/MedicaidAndEPSDT'},
      {name: 'Value-Based Care, Risk Stratification, and Preventive Health', sentence: 'Value-based care and payment systems have special implications to developing preventive health services.', link: '/keyIssues/ValueBasedCare'},
      {name: 'Exemplary Early Childhood Primary Care Practices', sentence: 'There are a growing array of exemplary programs, practices, and tools for advancing child health in the early childhood years.', link: '/keyIssues/EECPC'},
      {name: 'Opioids, Behavioral Health, and Foster Care', sentence: 'Opioid use and abuse affected children and youth both directly and through the impact opioid use and abuse by parents has on children’s health', link: '/keyIssues/OpioidsAndChildHealth'}
    ],
    webinars: [
      { date: "Webinar One: March 26, 2020", title: "Overview: Making the Case", slides: "/webinars/WebinarOneInCKOverviewM26.pdf", recording: "https://www.youtube.com/watch?v=igXGpcojGbY", workingPaper: "/webinars/OverrviewWorkingPaperSeriespdf.pdf"},
      { date: "Webinar Two: April 23, 2020", title: "Practice: What Practice Tells Us", slides: "/webinars/WebinarTwoInCKPracticeAp23_final.pdf", recording: "https://www.youtube.com/watch?v=wPCsuhQLBko", workingPaper: "/webinars/InCKMarksPracticeTransformationComponentfinalpdf.pdf"},
      { date: "Webinar Three: May 21, 2020", title: "COVID-19 and Child Health Care Transformation", slides: "/docs/COVIDWEBINARSLIDESPDF.pdf", recording: "https://www.youtube.com/watch?v=PChA-5K7RMI", workingPaper: "/webinars/workingpaper4pdf.pdf"},
      { date: "Webinar Four: June", title: "Equity, Transformation, and Early Childhood Systems Building", slides: "/webinars/WebinarFour_EquityHealthYoungChildren_062520_combined-Final.pdf", recording: "https://www.youtube.com/watch?v=95K72Q1aOyk", workingPaper: ""},
      { date: "Webinar Five, July", title: "Adolescence and Health Transformation", slides: "/webinars/Webinar5Adolescence.pdf", recording: "https://www.youtube.com/watch?v=XhILltXdr9g", workingPaper: ""},
      { date: "Webinar Six, September", title: "Policy: Toward a Transformed Standard of Care", slides: "/webinars/Webinar6POLICY2020.pdf", recording: "https://youtu.be/sgqCpi94xIM", workingPaper: ""},
    ]
  }
  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <Navbar/>
        <PageHeader
          pageTitle='Key Resources'
        />
        <div className="container font-poppins mt-3">
          <p>
          While child health care transformation is complex, that does not mean that leaders need to start from scratch.
          </p>
          <br/>
          <p>
          In fact, there is a substantial body of knowledge and significant consensus among experts on the progress in and state-of-the-field for each of the components of child health care transformation. There also is rapid growth, often through innovations at the practice level, that are continuously increasing understanding in the field.
          </p>
          
          {/* <p>
          InCK Marks does not seek to provide exhaustive reviews of the knowledge and research, but it does seek to provide the best existing overall summaries of the state-of-the-field and, in particular, the foundation upon which new efforts to advance child health care transformation can build.
          </p>
          <br/>
          <br/> */}
          <br/>
          <p>InCK Marks is developing state-of-the-field resources through its 20/20 child health transformation webinar series, described below:</p>

          <h5 className='mt-3 mb-3 pb-3 text-center'><span className="text-blue">InCK Marks</span> 20/20 Child Health Transformation Webinar Series</h5>

          <p>The InCK Marks 20/20 Child Health Transformation Webinar Series covers all key elements of child health transformation – with presentation slides, recordings of the webinars, and working papers for each webinar. These build upon the child health care transformation framework (practice, metrics, financing, and culture) developed by the National Advisory Team.</p>

          <br/><br/>
          {this.state.webinars.map( web => {
            return (
              <div className="row pt-1 pb-1 mb-1 bg-light border rounded" key={web.date}>
                <div className="col-md-4">
                  <div className="p-1">
                    <p>{web.date}</p>
                  </div>
                  
                </div>
                <div className="col-md-6">
                  <div className="p-1 text-red">
                    <h6>{web.title}</h6>
                  </div>
                </div>
                {web.slides? (
                  <div className="col-md-2">
                    <div className="p-1">
                      <a className='d-block text-blue mb-1' href={web.slides} target="_blank" rel="noopener noreferrer">Slides</a>
                      <a className='d-block text-blue mb-1' href={web.recording} target="_blank" rel="noopener noreferrer">Recording</a>
                      {web.workingPaper? (
                        <a className='d-block text-blue mb-1' href={web.workingPaper} target="_blank" rel="noopener noreferrer">Working Paper</a>
                      ):( null)}
                    </div>
                  </div>  
                ):(
                  null
                )}
                
                
            </div>
            )
          })}
          
          
        </div>
      </div>
    );
  }
};
