import React from 'react';
import Navbar from '../components/Navbar/Navbar.jsx';
import {Link} from 'react-router-dom';
import PageHeader from '../components/PageHeader/PageHeader.jsx';
import axios from 'axios';

class OurPartners extends React.Component {
  state={
    partnersFullInfo: [],
    partnersLimitedInfo: [],
    partnersNoInfo: []
  }
  componentDidMount=()=>{
    window.scrollTo(0, 0)
    axios
      .get('api/partners/limited')
      .then(res=>{
        for (let i=0; i < res.data.length; i++){
          if (res.data[i].constituency){
            this.setState(prevState => ({
              partnersFullInfo: [...prevState.partnersFullInfo, res.data[i]]
            }))
          }
          else if(res.data[i].summaryShort){
            this.setState(prevState => ({
              partnersLimitedInfo: [...prevState.partnersLimitedInfo, res.data[i]]
            }))
          }
          else{
            this.setState(prevState => ({
              partnersNoInfo: [...prevState.partnersNoInfo, res.data[i]]
            }))
          }
        }
      })
  }
  render() {
    // console.log(this.state.partners)
    return (
      <div className='font-poppins'>
        <Navbar/>
        <PageHeader
          pageTitle='Our Partners'
        />
        <div className="container mb-2 font-poppins">
          <h3 className='text-red font-weight-bolder mb-3 sticky-top bg-white pt-3 pb-3 border-bottom'>About the InCK Marks Project</h3>
          <p>There are many organizations and leaders committed to improving child health and with expertise to share in child health care transformation. The InCK Marks Project has enlisted many of these as partners to share resources and support, as well as established a national advisory team to guide development of further resources and supports.</p>
          <br/>
          <p>Charles Bruner serves as the <a href="#charles" className='font-weight-bold'>Resource Manager</a> for InCK Marks. Partners share information about themselves and specific resources their organizations have on aspects of the work. Funding Support is provided by the Robert Wood Johnson Foundation and the Perigee Fund. A National Advisory Team provides guidance to the overall work.</p>
          <br/>
          <div className="bg-lightblue border rounded">
          <h6 className="text-center mb-2 mt-2"><a href='#partners' className='text-blue'>Our Partners</a></h6>
          <div className='text-center'> 
            {this.state.partnersFullInfo.map(name=>( <p key={name.name}>{name.name}, </p> ))}
            {this.state.partnersLimitedInfo.map(name=>( <p key={name.name}>{name.name}, </p> ))}
          </div>
          <br/>
          <h6 className="text-center mb-2"><a href='#funders' className='text-blue'>Funding Support</a></h6>
          <div className="text-center">
            <p>Robert Wood Johnson Foundation</p>
            <p>The Perigee Fund</p>
          </div>
          <br/>
          </div>
        </div>
        <div className="bg-light-GF pb-3">
        <div className="container mb-2 font-poppins" id='charles'>
          <h3 className='text-red font-weight-bolder mb-3 sticky-top bg-light-GF pt-3 pb-3 border-bottom'>Resource Manager</h3>
          <div className="text-center float-left">
            <img src="./images/bruner.jpg" alt="" className="img-height-200 mr-2"/>
          </div>
          <p>Charles Bruner and BrunerChildEquity LLC is responsible for the initiation and design of this website and its resources. Charlie led work on the Child and Family Policy Center’s Healthy Equiy and Young Children Initiative, which included a learning collaborative of evidenced-based pediatric transformation programs and systems serving young children and their families. As importantly, Charlie has participated in and learned from many other leaders in the field in children’s health and well-being.</p><br/>
          <p>He has and continues to enlist partners in this effort – to provide specific resources in their areas of expertise related to child health care transformation and its different core elements. While individual resource network members may have different views or emphases and have not been asked to endorse any InCK Marks’ developed statements or resources, they all have valuable perspectives and resources to provide.</p><br/>
          <Link to='/aboutCharlesBruner' className='text-blue'>
            <div className='bg-lightblue border p-2 rounded btn-lightblue'>
              <p className="text-center font-weight-bolder">More Information about Charles Bruner</p>
            </div>
          </Link>
          <br/>
        </div>
        </div>
        {/* Advisory Team */}
          <div className="container mb-2 font-poppins" id='charles'>
            <h3 className='text-red font-weight-bolder mb-3 sticky-top bg-white pt-3 pb-3 border-bottom'>National Advisory Team</h3>
            <p>InCK Marks has enlisted a national advisory team to guide its work. The National Advisory Team’s guidance to the Project includes identifying resources, recommending specific topics for webinars and resource briefs, and participating in InCK Marks activities. The National Advisory Team developed and endorsed the overall child health care transformation framework and serves in an editorial capacity for InCK Marks working papers. Kay Johnson chairs and Maxine Hayes co-chairs the team.</p>
            <br/>
            <p>Other members are Kamala Allen. Mayra Alvarez, Melissa Bailey, Scott Berns, Christy Bethell, Elisabeth Burak, Paul Dworkin, Wendy Ellis, Jeff Hild, Shadi Houshyar, Nora Wells, and David Willis.  Click <a href="/docs/InCKMarksNationalAdvisoryTeamBios2020pdf.pdf" target='_blank' rel="noopener noreferrer" className='text-blue'>here</a> for brief biographies of the National Advisory Team.</p>
          </div>
        {/* </div> */}
        <div className='bg-light-GF pb-3' id='partners'>
          <div className="container">
            <h3 className='text-red  font-weight-bolder mb-3 pt-3 pb-3 sticky-top bg-light-GF border-bottom'>Our Partners <span className='text-dark'>&</span> Their Work</h3>
            <p>InCK Marks is enlisting partners to provide practical resources in their areas of special expertise and across the areas of practice transformation, metrics transformation, finance transformation, and culture transformation.</p>
            <br/>
            <p>Partners have provided their own brief statement of their work and, where appropriate, provided links to some of their current resources.</p>
            <br/>
            <div className="row">
              {this.state.partnersFullInfo.map(partner=>(
                <div className="col-sm-6 col-lg-4" key={partner._id}>
                <div className='h-100 m-1 p-1 bg-light border' key={partner._id}>
                  <Link to={`/OurPartners/${partner.nameTrunc}`} className='mb-3'>
                    <img src={`/images/logos/${partner.image}`} alt={partner.name} id='partner-logo'/>
                    {/* <h4>{partner.name}</h4> */}
                    <p className='text-sm'>{partner.summaryShort}</p>
                    <p className='text-center text-blue'>Click for More Information</p>
                  </Link>
                </div>
                </div>
              ))}
              {this.state.partnersLimitedInfo.map(partner=>(
                <div className="col-sm-6 col-lg-4">
                  <div className='h-100 p-1 m-1 bg-light border' key={partner._id}>
                  <img src={`/images/logos/${partner.image}`} alt={partner.name} id='partner-logo'/>
                    <p className='text-sm'>{partner.summaryShort}</p>
                    <br/>
                  </div>
                </div>
              ))}
            </div>
            {this.state.partnersNoInfo.map(partner=>(
              <div className='mb-1 p-1' key={partner._id}>
                <h4>{partner.name}</h4>
              </div>
            ))}
            
          </div>
        </div>
        <div className="container" id='funders'>
          <h3 className='text-red font-weight-bolder mb-3 pt-3 pb-3 bg-white sticky-top border-bottom'>Funding Support</h3>
          <p>This InCK Marks Resource Network is made possible by generous contributions from the Robert Wood Johnson Foundation and the Perigee Fund to BrunerChildEquity LLC. Network content is provided by BrunerChildEquity LLC and does not necessarily reflect the views of the funders.</p>
          <br/>
          <div className="bg-lightblue p-1 shadow-box rounded mb-3">
            <div className="text-center">
              <img src="/images/logos/RWJF.png" className='w-80 pt-1' alt="Robert Woods Johnson Foundation"/> 
            </div>
            <p className='text-muted border-top pt-1 mt-1'>The Robert Wood Johnson Foundation is the nation’s largest philanthropy dedicated solely to health. RWJF is working alongside others to build a national Culture of Health that provides everyone in America a fair and just opportunity for health and well-being.</p>
            <br/>
            <br/>
            <p className='text-center'><a href="https://www.rwjf.org/" target='_blank' rel="noopener noreferrer" className='text-blue'>The Robert Wood Johnson Foundation</a></p>
          </div>
          <div className="bg-lightblue p-1 shadow-box rounded">
            <div className="text-center">
              <img src="/images/logos/perigree.png" className='h-150-px pt-1' alt="Robert Woods Johnson Foundation"/> 
            </div>
            <p className='text-muted border-top pt-1 mt-1'>Perigee Fund is a national philanthropic endeavor committed to investing early in strong relationships for infants and toddlers, and works to advance the field of early childhood mental health and perinatal mental health.</p>
            <br/>
            <br/>
            <p className='text-center'><a href="https://perigeefund.org//" target='_blank' rel="noopener noreferrer" className='text-blue'>Perigee Fund</a></p>
          </div>
        </div>
      </div>
    );
  }
}

export default OurPartners;
