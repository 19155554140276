import React from 'react';
import Navbar from '../components/Navbar/Navbar.jsx';
import axios from 'axios';
import AddPartner from '../components/AdminComponents/Partner-Add/AddPartner.jsx'

class Admin extends React.Component {

  state={
    articles: [],
    article: {},
    partners: [],
    partner: [],
    subscribers: [],
    chosenState: ''
  }

  loadEmailList =()=>{
    axios.get('/api/subscribers/emailList')
      .then(res=>{
        this.setState({subscribers: res.data})
        console.log(res)
      })
  }
  loadEmailListByState =()=>{
    const subState = {state: this.state.chosenState}
    axios.post('/api/subscribers/emailList/State', subState)
      .then(res=>{
        this.setState({subscribers: res.data})
      })
  }
  loadArticles =()=>{
    axios.get('/api/articles')
      .then(res=>{
        this.setState({articles: res.data})
      })
  }
  loadPartners =()=>{
    axios.get('/api/partners/limited')
      .then(res=>{
        this.setState({partners: res.data})
      })
  }
  logout=()=>{
    axios.delete('/api/user/logout')
      .then(res=>{
        this.props.history.push('/login')
      })
  }
  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <Navbar/>
        
        <div className="font-poppins pt-3 mt-3 container">
        <AddPartner
          id={this.state.article.id}
        />

        
        
        <div className="p-1 shadow-box bg-lightblue mt-3">
        <h1>Email List</h1>
        <button className='font-poppin mt-3 h6 d-block mb-1' onClick={this.loadEmailList}>Get Email List</button>
        <button onClick={this.loadEmailListByState}>Get This State</button>        <input 
          type="text" 
          value={this.state.chosenState} 
          placeholder='Select a State'
          onChange={e=>(this.setState({chosenState: e.target.value}))}
        />
          <p>Email addresses</p>
          <br/>
          {this.state.subscribers.map(sub=>(
            <span key={sub._id}>{sub.email}, </span>
          ))}       
        </div>
        {/* 
        <h1 className='font-poppin mt-3' onClick={this.loadArticles}>Articles List</h1>
        {this.state.articles.map(art=>(
          <span key={art._id}>{art.title} </span>
        ))}
        <h1 className='font-poppin mt-3' onClick={this.loadPartners}>Partners</h1>
        {this.state.partners.map(partners=>(
          <span key={partners._id}>{partners.name} </span>
        ))} */}
        <button onClick={this.logout} className='mt-3 bg-red h1 text-white rounded'> LOGOUT</button>
        </div>
        
      </div>
    );
  }
}

export default Admin;