import React, { Component } from 'react';
import Navbar from '../../components/Navbar/Navbar.jsx';
import PageHeader from '../../components/PageHeader/PageHeader.jsx';

class EECPC extends Component {
  state = { 
    resources:[
      {
        title: 'Promoting Young Children’s (0-3) Socioemotional Development in Primary Care', 
        link: '/docs/keyissues/NICHQAriadneEinhornPromoting0-3ChildHealth.pdf',
        author: '(Ariadne Labs, Einhorn Family Foundation, and National Institute for Child Health Quality).', 
        desc: 'This paper describes six design elements for primary child health practices and five design elements related to them, with a list of evidenced-based practices.'
      },
      {
        title: 'Transforming Young Child Primary Health Care Practice: Building Upon Evidence and Innovation',
        link: '/GS/HEYC-Framework-Paper-PDF.pdf', 
        author: '(Health Equity and Young Children Initiative)', 
        desc: 'This paper draws upon the experiences of a dozen innovative primary child health practices in describing core components of high value primary care practice.'
      },
      {
        title: 'Using Medicaid to Improve the Health and Well-being of Women and Infants: Opportunities in the InCK Model in the Perinatal Period',
        link: '/docs/Inck_marks_prenatal_brief_050319_finalpdf.pdf', 
        author: '(InCK Marks Resource Network)', 
        desc: 'This resource brief describes specific opportunities within the perinatal period to improve maternal and infant health and, in some instances, provide cost savings.'
      },
      {
        title: 'Opportunities for Better Child Health: Health Practitioner Roles in Healthy Weight, Nutrition, and Combatting the Obesity Epidemic',
        link: '/docs/InCK Marks_obesity_resource_brief_April_050219_finalPDF.pdf', 
        author: '(InCK Marks Resource Network)', 
        desc: 'This resource brief describes emerging and evidenced-based practices that can be incorporated into the InCK Model to improve children’s health and reduce obesity.'
      },
    ]
  }

  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }
  render() { 
    return ( 
      <div className='font-poppins'>
        <Navbar/>
        <PageHeader
          pageTitle='Exemplary Early Childhood Primary Care Practices'
        />

        <div className="container mt-3">
          <p>During the first two years of life, almost all children are seen by a child health practitioner for a well-child check-up and most are seen multiple times. Fewer than twenty percent of children are in any formal form of child care, so the child health practitioner plays a particularly important potential role in the identification and initial response to both child health issues and family concerns. There is a growing array of evidenced-programs and practice that have shown high value in improving child health trajectories.</p>
          <h5 className="text-maroon mt-3 mb-2 pb-2 border-bottom">Resources</h5>
          <br/>
          {this.state.resources.map(resource=>(
            <div key='title' className='bg-light-GF rounded p-2 mb-2'>
              <a href={resource.link} target='_blank' rel="noopener noreferrer">
                <h6 className='text-blue'>{resource.title}</h6>
              </a>
              <p className="mt-1 text-muted text-sm font-weight-bold">{resource.author}</p>
              <p className='mt-1 '>{resource.desc}</p> 
            </div>
          ))}
        </div>
      </div>
     );
  }
}
 
export default EECPC;