import React, { Component } from 'react';
// import logo from './logo.svg';

class Footer extends Component {
  render() {
    return (
      <div className='pt-3 bg-dark w-100' style={{'marginTop': '60px'}}>
        <div className="text-center">
          <a href="/">
            <div className="bg-light circle d-inline-block p-1">
              <img src="/images/inckMarks.png" alt="" className='img-height-60'/>
            </div>
          </a>
        </div>
        <div className="container mt-3">
          <div className="row footerLinks">
            <div className='col-12 col-sm-4 text-center'>
              <a href='/inckbasics'>InCK Basics</a>
              <a href='/gettingstarted'>Getting Started</a>
            </div>
            <div className='col-12 col-sm-4 text-center'>
              <a href='/keyissues'>Key Issues</a>
              <a href='/guidingframework'>Guiding Framework</a>
              <a href='/ourpartners'>Our Partners</a>
            </div>
            <div className='col-12 col-sm-4 text-center'>
              <a href='/contact'>Contact</a>
              <a href='/admin'>Admin</a>
            </div>
          </div>
        </div>
        <p className="text-center text-muted font-poppins text-sm pt-3 pb-1">
          This site contains writings by a number of people
        </p>
        <p className="text-center text-lightblue font-poppins text-sm pt-3 pb-1">
          This site is built and maintained by Alex Bruner
        </p>
      </div>
    );
  }
}

export default Footer;