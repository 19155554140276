import React from 'react';
import Navbar from '../components/Navbar/Navbar.jsx';
import {Redirect} from 'react-router-dom';
import axios from 'axios';

class LoginPage extends React.Component {
  state={
    page: 'LoginPage',
    username: '',
    password: '',
    redirectToReferrer: false
  }
  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }

  signIn = () =>{
    const info = {username: this.state.username, password: this.state.password}
    axios.post('/api/user/login', info)
    .then(res=>{
      console.log(res);
      if (res.status === 200){
        this.setState({ redirectToReferrer: true });
      }
      else{
        console.log('temp')
      }
    })
    .catch(function(error){
      console.log(error);
    })
  }

  render() {
    let { from } = this.props.location.state || { from: { pathname: "/admin" } };
    let { redirectToReferrer } = this.state;

    if (redirectToReferrer) return <Redirect to={from} />;
    return (
      <div>
        <Navbar/>
        
        <div className="container mt-3 font-poppins">
          <div className="bg-lightblue text-center p-3 shadow-box border">
            <h6>Login</h6>
            <br/>
            <input 
              type="text" 
              className='h6 rounded p-1 border-0' 
              value={this.state.username} 
              onChange = {e=>this.setState({username: e.target.value})}
              placeholder='Username...'
            />
            <br/>
            <br/>
            <input 
              type="password" 
              className='h6 rounded p-1 border-0' 
              value={this.state.password}
              onChange = {e=>this.setState({password: e.target.value})}
              placeholder='Password...'
            />
            <br/>
            <br/>
            <button 
              type="submit" 
              className='h6 font-poppins rounded border' 
              onClick = {this.signIn}
            >
              Sign In
            </button>
          
          </div>
        </div>

      </div>
    );
  }
}

export default LoginPage;
