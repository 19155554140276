import React, { Component } from 'react';
// import {Link} from 'react-router-dom';
// import logo from './logo.svg';
import axios from 'axios';

class EmailSignUp extends Component {
  state={
    email: '',
    name: '',
    org: '',
    zip: '',
    emailError: '',
    btnClass: 'formBtn-hide'
  }
  validateEmail= (email)=>{
    const regexEmail = /^([A-Za-z0-9_\-\.+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,})$/;
    if (!regexEmail.test(email)){
      console.log('failed')
      this.setState({btnClass: 'formBtn-hide'})
    }
    else{
      console.log('passed')
      this.setState({btnClass: 'formBtn-show'})
    }
  }

  emailHandler= (event)=>{
    this.setState({email: event.target.value}, cb=>{
      this.validateEmail(this.state.email)
    })
  }

  handleEnter=(event)=>{
    event.preventDefault();

    let newSub = {
      name: this.state.name,
      email: this.state.email,
      organization: this.state.org,
      zipCode: this.state.zip
    }
    axios.post('/api/newSubscriber', newSub)
      .then((res)=>{
        if (res.data.formSuccess){
          alert(`Thank you for signing up! Your support means a lot to us!`)
          this.setState({name: '', email: '', org: '', zip: ''})
        }
        else{
          alert('Please make sure you have submitted your email address and check that the zip code is correct.')
        }

    })
  }

  render() {
    return (
      <div>
        <div className="bg-purple p-3 text-white text-center mt-3 shadow-box btn">
          <h3>Join Our Email List</h3>
          <form id='emailSignUpDisplay'>
          <input type="text" className=' h6 mt-2 text-center rounded' onChange={this.emailHandler} placeholder='Email...' value={this.state.email}/>
          <br/>
          <input type="text" className=' h6 mt-2 text-center rounded' onChange={e => this.setState({ name: e.target.value})} placeholder='Name...' value={this.state.name}/>
          <br/>
          <input type="text" className=' h6 mt-2 text-center rounded' onChange={e => this.setState({ org: e.target.value})} placeholder='Organization...' value={this.state.org}/>
          <br/>
          <input type="number" maxLength='5' className=' h6 mt-2 text-center rounded' onChange={e => this.setState({ zip: e.target.value})} placeholder='Zipcode...' value={this.state.zip}/>
          <br/><br/>
          <button onClick={this.handleEnter} className={this.state.btnClass}>Submit</button>
          {/* <input  type="submit" className='d-none' onSubmit={this.handleEnter}/> */}
          </form>
        </div>
      </div>
    );
  }
}

export default EmailSignUp;