import React from 'react';
// import {Link} from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar.jsx';
import PageHeader from '../components/PageHeader/PageHeader.jsx';
// import EmailSignUp from '../components/EmailSignUp/EmailSignUp.jsx'

class GuidingFramework extends React.Component {
  
  state={
    page: 'Guiding Framework',
    buttons:[
      {name: 'The Importance of an Integrated Approach to Child Health', sectionID: '#GF-Section1', num: 1},
      {name: 'Medicaid’s Critical Role', sectionID: '#GF-Section2', num: 2},
      {name: 'The Different Needs and Opportunities by Developmental Stage', sectionID: '#GF-Section3', num: 3},
      {name: 'The Importance of Preventive as Well as Treatment Responses', sectionID: '#GF-Section4', num: 4},
      {name: 'Evidenced-Based Programs and Practices on Which to Build', sectionID: '#GF-Section5', num: 5},
      {name: 'Value-Based Care as Broader than “Cost-Containment” Care', sectionID: '#GF-Section6', num: 6},
      {name: 'The Need for Broad Measures of Child Health', sectionID: '#GF-Section7', num: 7},
      {name: 'Appendix', sectionID: '#GF-Section8', num: 8}
    ]
  }
  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div className='font-poppins'>
        <Navbar/>
        <PageHeader
          pageTitle='Guiding Framework'
        />
        
        {/* Top Section */}
        <div className="container pt-3 pb-3 font-poppins">
          <p>The National Advisory Team has developed a framework for child health care transformation to organize the work and resources of InCK Marks. This brief, one-page document, sets out the interrelated elements of child health care transformation – practice, metrics, finance, and culture. Click <a href='/docs/guidingframework/NACSTATEMENTTRANSFORMATIONpdf.pdf' target='_blank' style={{'display': 'inline', 'color': 'blue'}}>here</a> to download the one-pager or scroll down to review, below.</p>
          <br/>
          <p>This framework is described in more detail in a companion document that makes the case for child health care transformation and is part of the InCK Marks Working Paper series. In addition, the original guiding framework for InCK Marks, constructed about the federal InCK Model, is still relevant and has an appendix that focuses upon how this framework can be applied to different child stages of development – perinatal, birth to three, three to six, six to twelve, twelve to eighteen, and eighteen to twenty-two. A glossary of terms often used in discussing child health care and how they relate to this framework for child health care transformation also is provided as a resource.</p>
          <br/>
          <div className="border p-2 bg-light mb-3">
            <p className='text-center text-red'><b>DEFINITIONS OF CHILD HEALTH AND HEALTH EQUITY</b></p>
            <br/>
            <p>Child health is a state of physical, mental, intellectual, social and emotional well-being and not merely the absence of disease or infirmity. Healthy children live in families, environments, and communities that provide them with the opportunity to reach their fullest developmental potential.</p>
            <p className='text-right text-red'>– World Health Organization</p>
            <br/>
            <p>Health equity is achieving the highest level of health for all people. Health equity entails focused societal efforts to address avoidable inequalities by equalizing the conditions for health for all groups, especially for those who have experienced socioeconomic disadvantage or historical injustices.</p>
            <p className='text-right text-red'>– Healthy People 2020</p>
          </div>
          <p>To this end, this Guiding Framework is based upon several fundamental tenets. [The brief descriptions are provided below, the downloadable version contains a more detailed version]</p>
        </div>
        
        {/* List of Frameworks Links */}
        <div className="container">
          <div className="pb-3" id='guidingFrameworksLinks'>
            {/* <div style={{'marginLeft': '2.5rem'}}> */}
            {/* <div className="row">
              {this.state.buttons.map(button=>(
                <div className="col-md-6 col-lg-4 text-center"><a className=' btn m-1 p-1 h-50-px bg-purple font-weight-bolder pt-2 text-white' href={button.sectionID}>{button.name}</a></div>
              ))}
            </div> */}
            {/* </div> */}
            <p className='mt-2'>Download the Child Healthcare Transformation Framework <a href='/docs/guidingframework/NACSTATEMENTTRANSFORMATIONpdf.pdf' target='_blank' style={{'display': 'inline', 'color': 'blue'}}>Here</a></p>
            <p className='mt-2'>Download the InCK Model Framework <a href='/docs/multiplepagepdfs/InCKMARKSGuidingFrameworkSection.pdf' target='_blank' style={{'display': 'inline', 'color': 'blue'}}>Here</a></p>
            <p className='mt-2'>Download the InCK Model Appendix <a href='/docs/multiplepagepdfs/INCKMARKSFRAMEWORKAPPENDIX.pdf' target='_blank' style={{'display': 'inline', 'color': 'blue'}}>Here</a></p>
            <p className='mt-2'>Download the Glossary <a href='/docs/guidingframework/GlossaryofTerms.pdf' target='_blank' style={{'display': 'inline', 'color': 'blue'}}>Here</a></p>
          </div>
        </div>


        <div className="bg-light-GF">
          <div className="container pb-3 pt-3">
            <iframe 
              title='NACSTATEMENTTRANSFORMATION'
              src="/docs/guidingframework/NACSTATEMENTTRANSFORMATIONpdf.pdf" 
              height="1200px" 
              width="100%"
            />
          </div>
        </div>
      
      </div>
    );
  }
}

export default GuidingFramework;
