import React from 'react';
// import {Link} from 'react-router-dom';
// import axios from 'axios';

class Test extends React.Component {
  state={
    name: ''
  }
  render() {
    return (
      <div className='font-poppins'>
        <a href="/ppts/The-Integrated-Care-for Children-(InCK)-Final.pptx" target="_blank">Click Me</a>
      </div>
    );
  }
}

export default Test;
