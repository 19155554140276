import React from 'react';
import Navbar from '../components/Navbar/Navbar.jsx';
import EmailSignUp from '../components/EmailSignUp/EmailSignUp.jsx';
import {Link} from 'react-router-dom';

class LatestResources extends React.Component {

  rscs=[
    // Children and the Geography of Vulnerability, Opportunity, and Resilience
    // WhyKidsandWhyCHWs.pdf
    {
      k: '000-a1-jre-709-hgfdh',
      title: "UPCOMING NASEM REPORTS AND EVIDENCED-BASED HEALTHY PARENTING PRACTICES.",
      date: '7-15-2024',
      description: [
        "Two important National Academy of Science, Engineering, and Medicine (NASEM) reports on child health are planned for release this year. First, the Healthy Parenting in Primary Care Collaborative, operating within NASEM’s Forum on Children's Well-Being, is completing a report on The State of Healthy Parenting Primary Care Interventions in Advancing Health Equity. Section Two of that report includes a summary of its scoping review of evidenced-based practices in primary care advancing healthy parenting. InCK Marks produced a detailed description of all the programs identified in that review as well as a summary of the review (below). Second, NASEM is producing a much more comprehensive and detailed report, Improving the Health and Wellbeing of Children and Youth through Health Care System Transformation. More information about both is available on the NASEM website."      ],
      links: [
        {k: "0000a-a1-45ds41423dewd-j", title: "HEALTH PARENTING SCOPING REVIEW PROGRAM DESCRIPTIONS AND SUMMARY", url: "/docs/7-15-2024/INCK MARKS DESCRIPTIONS AND SUMMARY FOR NASEM SCOPING REVIEW.pdf"},
      ],
      new: true
    },
    {
      k: '000-a1-jre-709-892314',
      title: "Who Ya’ Gonna’ Call? Pediatric Community Health Workers – Practice, Financing and Metrics.",
      description: [
        "InCK Marks has completed the second and third in its series of working papers on pediatric community health workers as core to operationalizing child health transformation and its emphasis upon family-centered, preventive/developmental, and whole child, relational health. They focus upon InCK Mark’s three core elements of child health transformation – practice, financing, and metrics.",
        "Why Kids and Why Community Health Workers? Primary Child Health Transformation to Rectify Racial Inequities and Improve Population Health, builds upon new policy attention to CHWs for advancing health in poor and medically underserved communities and the particular need to focus upon pediatric CHWs in doing so.",
        "Financing Pediatric Community Health Workers: The Role of Medicaid and the Medical Home, grew from work with the Center for Health Care Strategies and its Advancing Child Health Care Transformation initiative and outlines the key role Medicaid must play in financing CHWs for children.",
        "From Efficacy to Effectiveness; Community Health Workers, Medical Homes for Children, and the Measurement Systems to Support Them, grew from work the MCH/MRN Network and describes a systemic metrics framework of both implementation and impact metrics to optimize the effectiveness of incorporating CHWS in primary child health care."
      ],
      links: [
        {k: "0000a-a1-45ds41423dewd-j", title: "CHW Practice Paper", url: "/docs/11-17-2023/InCK Marks CHW Practice Working Paper-2.pdf"},
        {k: "0000a-b1-46ad42314231423eyry-g", title: "CHW Finance Paper", url: "/docs/11-17-2023/InCK Marks CHW Financing Working Paper.pdf"},
        {k: "0000a-b1-46ad42314231423eyry-y", title: "CHW Metrics Paper", url: "/docs/11-17-2023/InCK Marks CHW Metrics Working Paper.pdf"},
      ],
      new: true
    },
    {
      k: "0000-a1-04-3-32a4321",
      title: "Three Basic Truths and One Imperative (Invest in Child Health!)",
      description: [
        "InCK Marks and its Child Health Equity Leadership Group joined with the Center for Health Care Strategies’ Accelerating Child Health Transformation Initiative, Nemours Whole Child Health Alliance, CSSP’s Nurture Connections, Zero-to-Three’s Transforming Pediatrics in Early Childhood, and CAHMI’s Engagement in Action to host a special meeting, May 24th 2023, on “Advancing Federal Policy to Support Child Health Transformation and Equity.” Fifty leaders convened based upon three basic truths: (1) advancing health transformation to improve population health requires prioritizing child health investments, (2) achieving health equity requires an emphasis upon children’s healthy development, and (3) building robust community health systems in poor and medically-underserved communities requires priority attention to children and families. InCK Marks will continue to support the work of these leaders."
      ],
      links: [
        {k: "0000a-a1-45ds41423dewd", title: "Conclusions and Resources", url: "/docs/new/Conclusions_from_May_24th_Child_Health_Transformation_Meeting.pdf"},
        {k: "0000a-b1-46ad42314231423eyry", title: "May 24th Introductory Slides", url: "/docs/new/May_24th_Introductory_Slides.pdf"},
      ],
    },
    {
      k: "0000-a1-04-3-32a4321",
      title: "Child Health Transformation and Community Health Workers",
      description: [
        "A broad and diverse evidence base shows the value of well-resourced and supported CHWs in advancing relational health, responding to social determinants that impact health, and rectifying racial inequities, with the greatest overall impact in working with children and in poor and medically-underserved communities. InCK Marks’ special April webinar explored the opportunities to invest in CHWs with commentaries from pediatric experts (Maxine Hayes, Pradeep Gidwani, and David Willis), CHW workforce experts (Breanna Burke and Elizabeth Lee), and child advocates (Mayra Alvarez and Charlie Bruner), in the context of federal funding opportunities in the Prevent PANDEMICS Act (Kate Samuelson).",
        "The August InCK Marks working paper, Why Kids and Why Community Health Workers?, summarizes and references the evidence and research base on the key role CHWs, directed to children and their families, can play in advancing health equity and population health."
      ],
      links: [
        {k: "0000a-a1-45ds41423", title: "CHWs & Children Slides", url: "/docs/new/April26introductorypowerpoint.pdf"},
        {k: "0000a-b1-46ad42314231423", title: "CHWs & Children Video", url: "https://youtu.be/_-OtfZLsx5c"},
        {k: "0000a-b1-46ad42314231423rew", title: "Why Kids and Why Community Health Workers?", url: "/docs/new/WhyKidsandWhyCHWs.pdf"}

      ],
      new: true
    },
    {
      k: "0000-a1-04-3-32a",
      title: "Racial Equity and Child Health Summative Video and Slides",
      description: [
        "InCK Marks’ January, 2022 webinar, Racial Equity Truths: Health Care Imperatives, summarized its conclusions from six years of RWJF support and collaboration with leaders in the field. Charles Bruner opened by presented these truths and imperatives – 1. Prioritize child health, 2. Build on what works, and 3. Invest in value – followed by kick-off commentaries from Rahil Briggs and Mayra Alvarez. David Willis, Daniella Gratale, Jim Perrin, Sean Yoo, Gretchen Hammer, Pradeep Gidwani, Elisabeth Burak, Ed Schor, Robert Sege, and Dina Lieser then provided concise responses and additional key perspectives, with a synthesis by Kay Johnson and closing remarks by Maxine Hayes. The slides contain the text of Charles Bruner’s presentation, including highlights from other comments. The video contains the whole presentation. The synthesis by Kay Johnson provides overall themes from the webinar."
      ],
      links: [
        {k: "0000a-a1-45ds", title: "Slides", url: "/rethhi/RETHCIPowerpoint.pptx"},
        {k: "0000a-b1-46ad", title: "Video", url: "https://youtu.be/FsWEkLj8cyY"},
        {k: "0000a-b1-8r2902", title: "Synthesis", url: "/rethhi/RETHCISynthesisKayJohnson.pdf"},
      ],
      new: true
    },
    {
      k: "0000-a",
      title: "Children and the Geography of Vulnerability, Opportunity, and Resilience.",
      description: [
        "Children live in neighborhoods and communities which affect their health and development. The InCK Marks Statistical Note, The Geography of Vulnerability, Opportunity, and Resilience shows why it is imperative to define community below the county level and to make children a priority focus in those communities. It uses the CDC’s Social Vulnerability Index (SVI) to do so, including analyses from the states of Iowa, Texas, Washington, and Connecticut. InCK Marks also produced a Practical Guide for Using the SVI for states and communities (and CBOs working in underserved and disinvested communities) seeking to do similar analyses.", 

        "In addition to the above, InCK Marks has produced four state-specific reports using the SVI data. The California, Washington, Mississippi, and Texas reports all confirm the basic conclusions from the national analysis but provide particular areas for focus within states. Other states can use the InCK Marks guide to produce similar reports for their state."
      ],
      links: [
        {k: "0000a-a", title: "Statistic Note: Geography and Children", url: "/docs/newresources/InCKStatisticalNoteGeography.pdf"},
        {k: "0000a-b", title: "Guide to Using the SVI", url: "/docs/newresources/PRACTICALGUIDEFORSVI.pdf"},
        {k: "0000a-b4532", title: "California Report", url: "/docs/statereports/CaliforniaSVIReport.pdf"},
        {k: "0000a-b523534", title: "Washington Report", url: "/docs/statereports/WashingtonSVIReport.pdf"},
        {k: "0000a-b44325", title: "Mississippi Report", url: "/docs/statereports/MississippiSVIReport.pdf"},
        {k: "0000a-b76542", title: "Texas Report", url: "/docs/statereports/TexasSVIReportrevised.pdf"},
      ],
      nssr: true
    },
    {
      k: "0000-a1-04-3",
      title: "Advancing Child Health Transformation: Next Steps for the Field",
      description: [
        "Two final InCK Marks working papers outline the need to advance child health transformation through enhanced primary care for at least thirty percent of the child population and a much greater share of those covered by Medicaid or living in underserved and low-income communities. Working Paper 8, Medicaid, Managed Care, and Value-Based Payment Systems: State Roles in Contracting for Healthy Child Development, discusses the imperative of developing value-based payment systems for children employing an investment perspective (and not a cost containment one). Working Paper 9, Scope and Scale: Developing a Risk/Opportunity Strategy for Young Children and Their Families to Achieve Population Health, emphasizes young children require screening for and response to social (family and community) risks and not just child-specific conditions, with at least three in ten requiring enhanced services to impact health and reduce disparities at the population level."
      ],
      links: [
        {k: "0000a-a1-45", title: "WP8 Value Based Medicaid for Children", url: "/docs/newresources/Oct2021/WP8ValueBasedMedicaidforChildren.pdf"},
        {k: "0000a-b1-46", title: "WP9 Scope and Scale", url: "/docs/newresources/Oct2021/WP9ScopeandScale.pdf"},
        // {k: "0000a-b1-", title: "Messaging Framework", url: "/docs/newresources/Sept292021/INCK MARKS Messaging for Federal Policymakers.pdf"},
      ],
      new: true
    },
    {
      k: "0000-a1-04",
      title: "Building Back Better by Ensuring Child Health is Front-and-Center in Health Investments..",
      description: [
        "As other InCK Marks resources show, the science, research, and demographics are overwhelming that advancing racial equity requires child health to be at the forefront of health investments. InCK Marks’ September 29 Briefing and Dialogue presented a messaging framework to convey this to federal policy makers and promote federal investments in child health (health care and public health). The presentation and slides are based upon a messaging framework developed by InCK Marks with assistance from McCabe Messaging Partners."
      ],
      links: [
        {k: "0000a-a1-", title: "Briefing Presentation", url: "/docs/newresources/Sept292021/BRIEFINGRACIALEQUITYCHILDHEALTH.pdf"},
        {k: "0000a-b1-", title: "Briefing Recording", url: "https://youtu.be/cOD_7rd3z4Q"},
        {k: "0000a-b1-", title: "Messaging Framework", url: "/docs/newresources/Sept292021/INCK MARKS Messaging for Federal Policymakers.pdf"},
      ]
    },
    {
      k: "0000-a1",
      title: "Building Resilience and Achieving Child Equity.",
      description: [
        "The June 21 InCK Marks briefing and dialogue focused upon building resilience in underserved and low-income communities and the key role community-based health workers play in doing so. While only 3 percent of white children live in high vulnerability neighborhoods, 39 percent of BIPOC (Black, Indigenous, and other People of Color) children do. The slides and additional statistical note showing the need to build resilience in underserved and vulnerable neighborhoods with children as a priority population initiated the dialogue."
      ],
      links: [
        {k: "0000a-a1", title: "PLACE MATTERS: Community Resilience and Child Equity", url: "/docs/newresources/BuildingResilienceSlides.pdf"},
        {k: "0000a-b1", title: "Neighborhoods Children Statistics", url: "/docs/newresources/NeighborhoodsChildrenStatistics.pdf"},
      ]
    },
    {
      k: "0000a",
      title: "Dismantling Racism: Child Health Care’s Role",
      description: [
        "The May 2021 InCK Marks Briefing and Dialogue with the field focused upon the latest discussion brief in its child health transformation series: Dismantling Racism: 10 Compelling Reasons for Investing in a Relational/Community Health Workforce for Young Children and Their Families (Bruner, Hayes, Houshyar, Johnson, and Walker-Harding). The webinar recording, slides, and discussion brief present opportunities and imperatives for the child health system to contribute to dismantling racism and optimizing child health."
      ],
      links: [
        {k: "00a-a", title: "Dismantling Racism DB", url: "/docs/newresources/InCKDiscussionBriefDismantingRacismMay20.pdf"},
        {k: "00a-b", title: "Webinar Recording", url: "https://youtu.be/1AVtYxXxh8Q"},
        {k: "00a-c", title: "Briefing Slides", url: "/docs/newresources/InCKMarksMaY17DismantlinRacismFinal.pdf"}
      ]
    },
    {
      k: "000a",
      title: "Metrics transformation and Medicaid contracting working papers.",
      description: [
        "In addition to the working paper on a community health workforce (below), InCK Marks published two additional working papers: Child Health Care Transformation, Nurturing and Resilience: Developing Transformed Metrics for Young Child Health (Bruner and Bethell) and Medicaid Managed Care: Transformation to Accelerate Use of High Performing Medical Homes for Young Children (Johnson and Bruner)"
      ],
      links: [
        {k: "00a-a", title: "Metrics WP8", url: "/docs/newresources/InCKWP6MetricsTransformation.pdf"},
        {k: "00a-b", title: "Medicaid Managed Care WP6", url: "/docs/newresources/InCKWP5MedicaidManagedCare.pdf"},
        // {k: "00a-c", title: "Dismantling Racism DP", url: "/docs/newresources/InCKDiscussionBriefDismantingRacismMayFinal.pdf"}
      ]
    },
    {
      k: "00a",
      title: "Child Health Transformation and a Relational Health Workforce",
      description: [
        "InCK Marks Working Paper 7 includes commentaries by members of the National Advisory Team and describes the role and opportunities for advancing child health through expanding the health-related workforce to include frontline staff skilled in relational health care. The webinar recording and its slides provide a briefing on the concept of early relational health, the research base for providing relational health care for children, and the opportunities to advance this work through federal policy."
      ],
      links: [
        {k: "00a-a", title: "Working Paper 7", url: "/rsrcs/RelationalHealthWorkforceWP7.pdf"},
        {k: "00a-b", title: "Webinar Recording", url: "https://youtu.be/6DPKO_MW7NQ"},
        {k: "00a-c", title: "Briefing Slides", url: "/rsrcs/InCKMarksApril7Dialoguesfinal.pdf"}
      ]
    },
    {
      k: "0a",
      title: "Statement to the Next Administration on Child Health Care Transformation",
      description: [
        "The InCK Marks National Advisory Team developed a statement to the next administration on federal opportunities to advance child health care transformation. It modified these through four brainstorming sessions and enlisted leaders in the health practice and policy to sign onto the statement."
      ],
      links: [
        {k: "0a-a", title: "Sign-On Statement", url: "/docs/INCKSIGNON.pdf"},
        {k: "0a-b", title: "Themes from Brainstorming Sessions", url: "/docs/newresources/ThemesforCHCTandNextAdminstration.pdf"}
      ]
    },
    { 
      k: "1a", 
      title: "Frame[work Paper on Child Health Care Transformation",
      description: [
        "Provides a detailed framework for child health care transformation as composed of practice, metrics, finance, and culture transformation."
      ],
      links: [
        {k: "1a-a", title: "Framework paper", url: "/webinars/OverrviewWorkingPaperSeriespdf.pdf"},
        {k: "1a-b", title: "1-Page Summary", url: "/docs/guidingframework/NACSTATEMENTTRANSFORMATIONpdf.pdf"}
      ]
    },
    { 
      k: "2a", 
      title: "Young Child Health Care Transformation: What Practice Tells Us",
      description: [
        "Synthesizes current research on the current evidence base (very strong) for exemplary programs and practices in primary, preventive, promotive and developmental health services. Describes these as a field of practice transformations showing success in improving health and health equity."
      ],
      links: [
        {k: "2a-a", title: "Working Paper", url: "/webinars/InCKMarksPracticeTransformationComponentfinalpdf.pdf"},
      ]
    },
    { 
      k: "3a", 
      title: "Webinar Series – Slides and Recordings. The sixth webinar, on Child Health Transformation, Early Childhood Systems Building, and Public Policy",
      description: [
        "– with Martha Davis, Elisabeth Burak, Melissa Bailey, Mayra Alvarez, Karen Howard, and Joan Lombardi – describes opportunities for state and federal policy to advance child health and achieve health equity. Other webinar slides and recordings are on the “Key Resources” webpage."
      ],
      links: [
        {k: "3a-a", title: "Webinar 6 Recording", url: "https://www.youtube.com/watch?v=sgqCpi94xIM&feature=youtu.be"},
        {k: "3a-b", title: "Webinar 6 Slides", url: "/webinars/Webinar6POLICY2020.pdf"}
      ]
    },
    { 
      k: "4a", 
      title: "Child Health Care Transformation: Landscape of Federal and Foundation Initiatives and Model Dissemination Efforts",
      description: [
        "Summarizes the growing array of initiatives and model programs advancing child health transformation, their often parallel but aligned activities, and their collective work providing a critical mass of evidence for deepening and broadening adoption."
      ],
      links: [
        {k: "4a-a", title: "Landscape Paper", url: "/docs/LANDSCAPEOFFEDERALFNDTNINTITIVESJULY2020.pdf"},
      ]
    },
    { 
      k: "5a", 
      title: "COVID-19, Child Health Transformation, and Equity",
      description: [
        "Based upon interviews with practice champions and leaders, describes lessons being learned in pivoting to respond to COVID-19 and its impact upon child health, including insights on opportunities to “build back better, smarter, and fairer.” This information also was presented in the 4th InCK Marks webinar."
      ],
      links: [
        {k: "5a-a", title: "Working Paper", url: "/webinars/workingpaper4pdf.pdf"},
        {k: "5a-b", title: "Webinar 4 Recording", url: "https://www.youtube.com/watch?v=PChA-5K7RMI"},
        {k: "5a-c", title: "Webinar 4 Slides", url: "/docs/COVIDWEBINARSLIDESPDF.pdf"},
      ]
    },
    { 
      k: "6a", 
      title: "Other Resources",
      description: [
        "Other 2020 resources from InCK Marks, including links to the webinars and slides for all webinars, are on the “key resources” web page. Resources developed by InCK Marks and colleagues in 2019 to support review of the federal InCK model are found on the “Federal Inck Model” webpage."
      ],
      links: [
      ]
    },
    
  ]
  render(){
    return (
      <div className="mt-1 mb-3 p-3 bg-lightblue shadow-box">
        <h4 className="text-maroon">Latest Resources</h4>

        {this.rscs.map( (rsc, i) => {
          return (
            <div key={rsc.k} className='mt-3 pt-3'>
              { rsc.date ? <p >{rsc.date}</p> : null}
              <p className='font-weight-bold'>
                { i === 0 ? <span><span className='text-danger'>NEW </span> - </span> : null } 
                { rsc.nssr ? <span><span className='text-danger'>NEW STATE-SPECIFIC REPORTS</span> - </span> : null } 
                {rsc.title}
              </p>
              
              {rsc.description.map( rscdesc => {
                return <div><p>{rscdesc}</p><br/></div>;
              })}
              
              
                {rsc.links.map( link => {
                  return (
                    <a href={link.url} style={{textDecoration: 'none'}} target="_blank" rel="noopener noreferrer">
                      <button className="button mr-2 mb-1 p-1 font-poppins" key={link.k}>
                        {link.title} <i className="fas fa-download"></i>
                      </button>
                    </a>
                  )
                })}
            </div>
          )
        })}

        {/* <h6 className='font-weight-bold mb-2'>Framework Paper on Child Health Care Transformation </h6>
        <p>Provides a detailed framework for child health care transformation as composed of practice, metrics, finance, and culture transformation.
        </p>
        <div className="row mt-2 mb-2">
          <div className="col-sm-6">
            <p className='text-center'>Framework paper</p>
          </div>
          <div className="col-sm-6">
            <p className='text-center'>1 page Summary</p>
          </div>
         
        </div> */}


        {/* <p>The InCK Marks National Advisory Team has provided a <a className='text-blue' href="/docs/guidingframework/NACSTATEMENTTRANSFORMATIONpdf.pdf" target="_blank">framework</a> for child health care transformation which emphasizes the need for practice transformation, metrics transformation, finance transformation, and culture transformation.</p>
        <br/>
        <p>The first Working Paper in an InCK Mark series on “Health Care Transformation and Young Children” provides an <a className='text-blue' href="/docs/InCKOverviewWorkingPaperpdf.pdf" target="_blank">overview</a> of the state-of-the-field on transformation across practice, metrics, finance, and culture. The first 20/20 Webinar on Child Health Transformation builds upon this overview, with the slides and recording available on the “key resources” page.</p>
        <br/>
        <p>The first 20/20 Webinar on Child Health Transformation is scheduled for Thursday, March 26th, at 2:00 eastern. Click <a className='text-blue' href="https://www.cfpciowa.org/index.cfm?NodeID=95654&AudienceID=1&preview=1" target="_blank">here</a> to register for the webinar.</p>
        <p>The first 20/20 Webinar on Child Health Transformation occurred Thursday, March 26th, at 2:00 eastern. Click <a className='text-blue' href="/webinars/inckoverviewwebinarslidesM26.pdf" target="_blank">here</a> for the slides used in the webinar.</p>

        <br/>

        <p>The second 20/20 Webinar on Child Health Transformation is scheduled for Thursday, April 23rd, at 2:00 eastern. Click <a href="/" target="_blank" className='text-blue'>here</a> to register for the webinar.</p>
        <br/>

        <p>InCK Marks also is providing short commentaries on the implications of COVID-19 to children, child health care transformation, and health equity. 
        </p><br/><p>
        Click <a className='text-blue'href="/docs/COVID19InCKMarksShortTakeTransformationpdf.pdf" target="_blank">here</a> for short-term and longer-term implications to child health care. 
        </p><br/><p>
        Click <a className="text-blue" href="/docs/covid-19/shorttakecovidhealthequity.pdf">here</a> for a discussion of COVID-19 and child equity and click <a className="text-blue" href="/docs/covid-19/HelpersCoronavirusElectionsPDF.pdf">here</a> for the critical role frontline workers in the helping professions play and need to be supported in public policy.</p>
        <br/>
        <p>Click <a href="/docs/covid-19/shorttakeElliscovidequitycleanpdf.pdf" className="text-blue">here</a>  for the importance of dealing with health inequities from COVID-19 at a community as well as family level</p>
        <br/> */}
      </div>
    )
  }
}

class Home extends React.Component {

  state={
    page: 'Home Page'
  }
  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <Navbar/>
        <div className="mainImage text-center">
        <div className='w-100 h-300-px bg-muted opaque-5' style={{'position': 'absolute'}}/>
          {/* <img src="/images/cloudTransparent.png" alt="" className='img-width-200 bg-light opaque-8' id='wordCloudImage'/> */}
          <img src="/images/transformationcircle.png" alt="" className='img-width-200 bg-light opaque-8' id='wordCloudImage'/>
        </div>
        <div className="container mt-3 font-poppins">
          <div>
            <h4 className="text-maroon mb-2">ABOUT InCK Marks</h4>
            <p>InCK Marks is dedicated to helping states, communities, health experts, practitioner champions, and advocates advance child health care transformation at the federal, state, and community levels.</p>
          </div>
          <LatestResources/>

          <div className="row" style={{'margin': '-.5rem'}}>

            <div className="col-sm-6 col-md-4">
              <div className="p-1 link-home">
                <Link to='/OurPartners'>  
                  <div className='divImageHome link-home' style={{'backgroundImage': 'url(/images/diversity.jpg)'}}>
                    <div className='link-home-text'>
                      <p> The people and organizations involved.</p>
                    </div>
                    </div>
                  <p className='text-white bg-info text-center p-1'>
                  Our Partners</p>
                </Link>    
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="p-1 link-home">
                <Link to='/guidingFramework'>  
                  <div className='divImageHome link-home' style={{'backgroundImage': 'url(/images/framework.jpg)'}}>
                    <div className='link-home-text'>
                      <p> Guiding Framework provides an orientation to child health transformation and its financing in terms of addressing the needs and opportunities of children by their stages of development (perinatal, birth to 3, 3 to 6, 6-11, 12-16, and 17-20) by their current health status and risks, and through a value-based payment and delivery system lens.</p>
                    </div>
                    </div>
                  <p className='text-white dark bg-blue text-center p-1'>
                  Guiding Framework</p>
                </Link>    
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="p-1 link-home">
                <Link to='/KeyResources'>  
                  <div className='divImageHome' style={{'backgroundImage': 'url(/images/dentist.jpg)'}}>
                    <div className='link-home-text'>
                      <p> Resources are under development.</p>
                    </div>
                    </div>
                  <p className='text-white dark bg-maroon text-center p-1'>
                  Key Resources</p>
                </Link>    
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="p-1 link-home">
                <Link to='/federalInckModel'>  
                  <div className='divImageHome link-home' style={{'backgroundImage': 'url(/images/protest.jpg)'}}>
                    <div className='link-home-text'>
                      <p> Federal Inck Model provides resources to inform specific elements of the InCK model – including “opioids and child health,” “foster care and Medicaid,” “two generation approaches in Medicaid,” and “evidenced-based primary care pediatric models,” “evidenced-based pediatric models for children with special/stratified health care needs,” and “value-based care tools.”</p>
                    </div>
                    </div>
                  <p className='text-white bg-dark text-center p-1'>
                  Federal InCK Model</p>
                </Link>    
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="p-1 link-home">
                <Link to='/webinars'>  
                  <div className='divImageHome link-home' style={{'backgroundImage': 'url(/images/diversity-kids.jpg)'}}>
                    <div className='link-home-text'>
                      <p> Download or view archived webinars.</p>
                                    </div>
                    </div>
                  <p className='text-white bg-orange text-center p-1'>Webinars</p>
                </Link>    
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="p-1 link-home">
                <Link to='/contact'>  
                  <div className='divImageHome link-home' style={{'backgroundImage': 'url(/images/email.jpg)'}}>
                    <div className='link-home-text'>
                      <p> Contact Us provides opportunities for providing feedback, asking questions, and signing up for updates.</p>
                    </div>
                    </div>
                  <p className='text-white bg-green text-center p-1'>
                  Contact</p>
                </Link>    
              </div>
            </div>
          </div>
          {/* <div className="p-2 bg-light shadow-box">
          
          <br/>
          <p><Link to='/InckBasics' className='h3 text-info font-weight-bold'>InCK Basics</Link> provides a quick summary of and key resources from CMMI about the InCK model.</p>
          <br/>
          <p><Link to='/GettingStarted' className='h3 text-info font-weight-bold'>Getting Started</Link> offers suggestions of who to involve in the initial review of InCK.</p>
          <br/>
          <p><Link to='/GuidingFramework' className='h3 text-info font-weight-bold'>Guiding Framework</Link> provides an orientation to child health transformation and its financing in terms of addressing the needs and opportunities of children by their stages of development (perinatal, birth to 3, 3 to 6, 6-11, 12-16, and 17-20) by their current health status and risks, and through a value-based payment and delivery system lens.</p>
          <br/>
          <p><Link to='/KeyIssues' className='h3 text-info font-weight-bold'>Key Issues</Link> provides resources to inform specific elements of the InCK model – including “opioids and child health,” “foster care and Medicaid,” “two generation approaches in Medicaid,” and “evidenced-based primary care pediatric models,” “evidenced-based pediatric models for children with special/stratified health care needs,” and “value-based care tools.”</p>
          <br/>
          <p><Link to='/Contact' className='h3 text-info font-weight-bold'>Contact Us </Link>provides opportunities for providing feedback, asking questions, and signing up for updates.</p>
          </div> */}
          <EmailSignUp/>
        </div>
      </div>
    );
  }
}

export default Home;
