import React from 'react';
import Navbar from '../components/Navbar/Navbar.jsx';
// import {Link} from 'react-router-dom';
import PageHeader from '../components/PageHeader/PageHeader.jsx';
// import axios from 'axios';

class AboutCharles extends React.Component {
  render() {
    return (
      <div className='font-poppins'>
        <Navbar/>
        <PageHeader
          pageTitle='About Charles'
        />
        <div className="container mt-3 mb-2 font-poppins">
          <h3 className='text-red font-weight-bolder mb-3'>About Charles Bruner</h3>
          <div className="text-center">
            <img src="./images/bruner.jpg" alt="" className="img-height-200 float-left mr-2"/>
          </div>
          <p>Charles Bruner currently serves as a Senior Fellow at the Center for the Study of Social Policy and at the RISE Institute, as well as a FCPA (freelance child policy agitator). In 2016, Bruner retired as Founding Director of the Child and Family Policy Center (CFPC), which he established in 1989 to “link research and policy on issues vital to children and families.” Prior to that, he served 12 years in the Iowa General Assembly, the last eight as a state senator. He holds a Ph.D. from Stanford University in political science</p>
          <br/>
          <p>Bruner consults with national foundations and state and federal policy makers and advocates for developing more comprehensive and holistic responses to vulnerable children. He is recognized for synthesizing research and using the best available knowledge and information to develop policy through collaborative work across researchers, policy makers, advocates, and children and families with the greatest stake in improving children’s opportunities and lives.</p>
          <br/>
          <p>Among Bruner’s publications are <i> Village Building and School Readiness</i>, <i>Clinical Health Care and Community Building (with Ed Schor)</i>, <i>Early Learning Left Out</i>, <i>More Than Health Insurance (with Jim Perrin)</i>, <i>Wise Counsel: Exploring the Relationship Between Families</i>, <i>Community Workers, and Professionals in the Helping Process</i>, and <i>Ace, Place, Race, and Poverty: Building Hope for Young Children</i>. He received the Florette Angel Memorial award in 2012 from Voices for America’s Children for his contributions to the field of child advocacy. The website, <a href={"https://www.childequity.org"} className='text-blue' target='_blank' rel="noopener noreferrer"> www.childequity.org</a>, offers an introduction to his work and thinking.</p>
          <br/>
        </div>
      </div>
    );
  }
}

export default AboutCharles;
