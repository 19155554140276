import React, { Component } from 'react';
import Navbar from '../../components/Navbar/Navbar.jsx';
import PageHeader from '../../components/PageHeader/PageHeader.jsx';

class OpioidsAndChildHealth extends Component {
  state = { 
    resources:[
      {
        title: 'State Strategies to Meet the Needs of Young Children and Families Affected by the Opioid Crisis', 
        link: '/docs/keyissues/OPIODSArticleNASHP.pdf',
        author: '(National Academy for State Health Policy).', 
        desc: ''
      },
      {
        title: 'The Ripple Effect: The Impact of the Opioid Epidemic on Children and Families – Recommendations for an Action Agenda Based Upon a Multidisciplinary Meeting', 
        link: '/docs/RippleEffectOpioidsMilbank.pdf',
        author: 'United Hospital Fund and Milbank Memorial Fund', 
        desc: ''
      },      
      {
        title: 'Opportunities for Child Welfare and Medicaid Collaboration: Strategies for Reducing Placement and Improving Health', 
        link: '/docs/childwelfaremedicaidcsspfinalpdf.pdf',
        author: 'Center for the Study of Social Policy and InCK Marks', 
        desc: ''
      },
      {
        title: 'Opportunities to Improve Services for Children and Adolescents to Address or Prevent Behavioral Health Conditions', 
        link: '/docs/InckAdolescentdraftAprilversionfinalpdf.pdf',
        author: 'Mental Health America and InCK Marks ', 
        desc: ''
      }
    ]
  }

  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }
  render() { 
    return ( 
      <div className='font-poppins'>
        <Navbar/>
        <PageHeader
          pageTitle='Opioids, Behavioral Health, and Foster Care'
        />

        <div className="container mt-3">
          <p>
          The InCK Model places special emphasis upon addressing the opioid crisis and reducing the placement of children into foster care or other out-of-home placements due to behavioral health conditions. This includes prevention and treatment services for children and adolescents regarding their own substance use, including opioids, and treatment and response services for children who are in families where there is opioid use and abuse, which impacts the child’s home safety and nurturing.</p>
          <h5 className="text-maroon mt-3 mb-2 pb-2 border-bottom">Resources</h5>
          <br/>
          {this.state.resources.map(resource=>(
            <div key='title' className='bg-light-GF rounded p-2 mb-2'>
              <a href={resource.link} target='_blank' rel="noopener noreferrer">
                <h6 className='text-blue'>{resource.title}</h6>
              </a>
              <p className="mt-1 text-muted text-sm font-weight-bold">{resource.author}</p>
              <p className='mt-1 '>{resource.desc}</p> 
            </div>
          ))}
        </div>
      </div>
     );
  }
}
 
export default OpioidsAndChildHealth;