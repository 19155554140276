import React from 'react';
import {Link} from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar.jsx';
import PageHeader from '../components/PageHeader/PageHeader.jsx';

class KeyIssues extends React.Component {
  state={
    page: 'Federal InCK Model',
    startingResources: [
      {name: 'Medicaid and EPDST', sentence: 'Medicaid and its EPSDT benefit provide multiple opportunities for improving child health.', link: '/MedicaidAndEPSDT'},
      {name: 'Value-Based Care, Risk Stratification, and Preventive Health', sentence: 'Value-based care and payment systems have special implications to developing preventive health services.', link: '/keyIssues/ValueBasedCare'},
      {name: 'Exemplary Early Childhood Primary Care Practices', sentence: 'There are a growing array of exemplary programs, practices, and tools for advancing child health in the early childhood years.', link: '/keyIssues/EECPC'},
      {name: 'Opioids, Behavioral Health, and Foster Care', sentence: 'Opioid use and abuse affected children and youth both directly and through the impact opioid use and abuse by parents has on children’s health', link: '/keyIssues/OpioidsAndChildHealth'}
    ]
  }
  componentDidMount=()=>{
    window.scrollTo(0, 0)
  }
  render() {
    return (
      <div>
        <Navbar/>
        <PageHeader
          pageTitle='Federal InCK Model'
        />
        <div className="container font-poppins mt-3">
          <p>
          InCK Marks initially was established to support states and their leaders in applying for the federal Integrated Care for Kids (InCK) model. Seven states (Connecticut, Illinois, New Jersey, New York, North Carolina, Ohio, and Oregon) received 5 year-awards for funding under InCK model cooperative agreements. InCK Marks will continue to track, learn from, and provide resources developed through the federal Integrated Care for Kids (InCK) model, but it will also draw upon the growing field of child health transformation efforts around the country, including a emerging set of initiatives focused upon young children.
          </p>
          <br/>
          <p>
          Many of the InCK Marks resources developed, including the guiding InCK Model framework, remain relevant and are accessible on this page.
          </p>
          <br/>
          {/* <p>
            This section will be assembling state-of-the-field resources for use in tackling different issues raised by the InCK model. It will offer particular examples of evidenced-based programs and practices that can be integrated into value-based payment and service delivery systems.
          </p> */}
          <br/>
          <div className="p-1 bg-lightblue shadow-box">
            <h5 className='text-red pt-2 pb-2 border-bottom'>Starting Resources</h5>
            <br/>
            {this.state.startingResources.map(resource=>(
              <div key={resource.name} className='mb-3'>
                <Link to={resource.link}>
                <p className='text-blue'>{resource.name}</p>
                <p className="text-sm">{resource.sentence}</p>
                </Link>
              </div>
            ))}

          </div>
        </div>
      </div>
    );
  }
}

export default KeyIssues;
