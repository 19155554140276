import React from 'react';
import Navbar from '../components/Navbar/Navbar.jsx';
// import {Link} from 'react-router-dom';
// import PageHeader from '../components/PageHeader/PageHeader.jsx';
import axios from 'axios';

class Partner extends React.Component {
  state={
    name: '',
    webUrl: '',
    image: '',
    summary: '',
    constituency: '',
    howHelps: '',
    categories: [],
    keyContacts: [],
    articles: []
  }

  
  componentDidMount=()=>{
    window.scrollTo(0, 0);
    const { match: { params } } = this.props;
    axios
      .get(`/api/partners/${params.nameTrunc}`)
      .then(res=>{
        console.log(res.data[0])
        this.setState(
          {
            name: res.data[0].name,
            webUrl: res.data[0].webURL,
            image: res.data[0].image,
            summary: res.data[0].summaryLong,
            constituency: res.data[0].constituency,
            howHelps: res.data[0].howHelps,
            // categories: [...this.state.categories, {prenatal: res.data[0].categories[0].prenatal}],
            // // categories: [...this.state.categories, {earlyChildhood: res.data[0].categories[0].earlyChildhood}] ,
            // elementary: res.data[0].categories[0].elementary ,
            // adolescent: res.data[0].categories[0].adolescent ,
            // youthToAdult: res.data[0].categories[0].youthToAdult ,
            // allAges: res.data[0].categories[0].allAges ,
            // // Health Role by Population
            // fosterCare: res.data[0].categories[0].fosterCare ,
            // specialNeeds: res.data[0].categories[0].specialNeeds ,
            // opioidAddiction: res.data[0].categories[0].opioidAddiction ,
            // mentalHealth: res.data[0].categories[0].mentalHealth ,
            // ACEs: res.data[0].categories[0].ACEs ,
            // // Health Role In Primary Care
            // primaryPracticioner: res.data[0].categories[0].primaryPracticioner ,
            // careCoordination: res.data[0].categories[0].careCoordination ,
            // healthRelatedServices: res.data[0].categories[0].healthRelatedServices ,
            // popHealthApproaches: res.data[0].categories[0].popHealthApproaches ,
            // placeBased: res.data[0].categories[0].placeBased ,
            // // Health Financing Issues
            // medicaid: res.data[0].categories[0].medicaid ,
            // vbPayments: res.data[0].categories[0].vbPayments ,
            // pacb: res.data[0].categories[0].pacb ,
            // financingCoordination: res.data[0].categories[0].financingCoordination ,
            // financialScreening: res.data[0].categories[0].financialScreening ,
            // financingHomeVisits: res.data[0].categories[0].financingHomeVisits ,
            // financingWellChildVisits: res.data[0].categories[0].financingWellChildVisits ,
            // braidingMCH: res.data[0].categories[0].braidingMCH ,
            // fundingPrimaryCare: res.data[0].categories[0].fundingPrimaryCare
          }
        )
        res.data[0].keyContacts.forEach(contact=>{
          this.setState({keyContacts: [...this.state.keyContacts, contact]})
        })
        res.data[0].articles.forEach(article=>{
          this.setState({articles: [...this.state.articles, article]})
        })
        console.log(this.state)
      })
  }
  render() {
    return (
      <div className='font-poppins'>
        <Navbar/>
        <div className="mainImage text-center">
          <div className='w-100 h-300-px bg-info opaque-8' style={{'position': 'absolute'}}/>
            <p 
              className='text-lg font-amatic font-weight-bold p-1 text-center d-inline text-white' 
              style={{'position': 'relative', 'top': '110px', 'lineHeight': '42px'}}
            >
              {this.state.name}
            </p>          
        </div>
        <div className="container mt-3">
          <p className="h4 text-white bg-maroon rounded-top p-1 d-inline-block">About</p>
          <p className='pt-1 border-top'>{this.state.summary}</p>
          <p className="h4 text-white bg-maroon rounded-top mt-2 p-1 d-inline-block">Constituency</p>
          <p className='pt-1 border-top'>{this.state.constituency}</p>
          <p className="h4 text-white bg-maroon rounded-top mt-2 p-1 d-inline-block">What We Do</p>
          <p className='pt-1 border-top'>{this.state.howHelps}</p>
        </div>
        <div className="bg-light-GF pt-3 pb-3 mt-3">
          <div className="container">
            <div className="text-center">

            <p className=" h3 text-maroon mb-1 "><u>Publications</u></p>
            {this.state.articles.map(article=>(
              <div key={article._id} className=''>
                <a href={article.urlLink}><p className='text-blue'>{article.title}</p></a>
              </div>
            ))}
            </div>
          </div>
        </div>
        <div className="container">
        <p className="h4 text-white bg-maroon rounded-top mt-3 p-1 d-inline-block">Key Contacts</p>
          <div className="mt-2 row">
            {this.state.keyContacts.map(contact=>(
              <div key={contact._id} className='col-sm-4 text-center'>
                <div className="m-1">
                <p style={{'lineHeight': '18px'}}>{contact.name}</p>
                <p style={{'lineHeight': '16px'}} className="text-sm">{contact.title}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
    );
  }
}

export default Partner;
